import React from "react";
import {useNavigate} from "react-router-dom";
import {AddCode} from "@/components/organisms/AddCode";
import {UrlHome} from "@/constants";
import {Code} from "@/types";
import {useAppStateStore} from "@/stores/keyforgeState";
type PageAddCodeProps = {}

export const PageAddCode: React.FC<PageAddCodeProps> = () => {
    const navigate = useNavigate();
    const {addNotice} = useAppStateStore();

    const handleSuccess = (code: Code) => {
        addNotice({
            style: "success",
            type: "notify",
            message: `Code "${code.label}" created successfully!`
        })
        navigate(UrlHome())
    }
    return (
        <div className="mx-auto max-w-prose py-12 px-6 border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500">
            <AddCode onSuccess={handleSuccess} onError={(err) => { console.log("Error creating code: "  + err)}} />
            <p className="text-center italic"><b>Reminder</b>: If this account is owned by an organization, Admins will be able to see a record of activity even if the code is your own, individual account. If this code is for personal, non-business use, you should store it within a Personal Plan instance.</p>
        </div>
    );
}
