import { RouteObject } from "react-router-dom";
import { PageDashboard, PageNotifications } from "@/components/pages/dashboard";
import {
    PageInviteSignup,
    PageLogin,
    PageVerifyEmail,
    Suspended,
    Feature
} from "@/components/pages/public";
import {
    PageOrganizationAppsConfigure,
    PageOrganizationAppsList,
    PageOrganizationBilling,
    PageOrganizationCodeManage,
    PageOrganizationCreate,
    PageOrganizationGroups,
    PageOrganizationGroup,
    PageCreateOrganizationGroup,
    PageOrganizationUser,
    PageOrganizationUserInvite,
    PageOrganizationUserManage,
    PageAccountSettings,
    PageOrganizationSelection
} from "@/components/pages/organizations";
import {
    PagePreferences,
} from "@/components/pages/settings";
import {
    PageAddCode
} from "@/components/pages/code/Add";
import {
    LayoutMain,
    LayoutOrganization,
    LayoutPublic,
} from "@/components/layouts";

export const routes: Array<RouteObject> = [
    {
        element: <LayoutPublic />,
        children: [
            {
                path: "login",
                element: <PageLogin />,
            },
            {
                path: "suspended",
                element: <Suspended />
            },
            {
                path: "feature",
                element: <Feature />
            },
            {
                path: "organizations/create",
                element: <PageOrganizationCreate />,
            },
        ],
    },
    {
        path: "/",
        element: <LayoutMain />,
        children: [
            {
                index: true,
                element: <PageDashboard />,
            },
            {
                path: "invite/:code",
                element: <PageInviteSignup />,
            },
            {
                path: "verify/:code",
                element: <PageVerifyEmail />,
            },
            {
                path: "notifications",
                element: <PageNotifications />,
            },
            {
                path: "add-code",
                element: <PageAddCode />,
            },
            {
                path: "settings",
                element: <PagePreferences />
            },
            {
                path: "organizations",
                element: <PageOrganizationSelection />,
            },
            {
                path: "organizations/:orgKey",
                element: <LayoutOrganization />,
                children: [
                    {
                        index: true,
                        element: <PageAccountSettings />,
                    },
                    {
                        path: "settings",
                        element: <PageAccountSettings />,
                    },
                    {
                        path: "apps",
                        element: <PageOrganizationAppsList />,
                    },
                    {
                        path: "apps/:orgAppUuid",
                        element: <PageOrganizationAppsConfigure />,
                    },
                    {
                        path: "billing",
                        element: <PageOrganizationBilling />,
                    },
                    {
                        path: "codes/:code_uuid",
                        element: <PageOrganizationCodeManage />,
                    },
                    {
                        path:"groups/:group_uuid",
                        element: <PageOrganizationGroup />,
                    },
                    {
                        path: "groups",
                        element: <PageOrganizationGroups />,
                    },
                    {
                        path: "groups/create",
                        element: <PageCreateOrganizationGroup />,
                    },
                    {
                        path: "users",
                        element: <PageOrganizationUserManage />,
                    },
                    {
                        path: "users/:user_uuid",
                        element: <PageOrganizationUser />,
                    },
                    {
                        path: "users/invite",
                        element: <PageOrganizationUserInvite />,
                    },
                ],
            },
        ],
    },
];
