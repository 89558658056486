import {create} from "zustand";
import {getDefaultCode, Code} from "@/types";

export type CodeStateStore = {
    code: Code;
    setLabel: (label: string) => void;
    setAlias: (alias: string) => void;
    setIssuer: (issuer: string) => void;
    setAccount: (account: string) => void;
    reset: () => void;
    load: (group: Code) => void;
};

export const useCodeStore =  create<CodeStateStore>((set: any) => ({
    code: getDefaultCode(),
    setLabel: (label: string) => {
        set((state: any) => ({code: {...state.code, label: label}}));
    },
    setAlias: (alias: string) => {
        set((state: any) => ({code: {...state.code, alias: alias}}));
    },
    setIssuer: (issuer: string) => {
        set((state: any) => ({code: {...state.code, issuer: issuer}}));
    },
    setAccount: (account: string) => {
        set((state: any) => ({code: {...state.code, account: account}}));
    },
    reset: () => {
        set(() => ({code: getDefaultCode()}));
    },
    load: (code: any) => {
        if (code?.created_at?.length > 0) {
            code.created_at = new Date(code.created_at);
        }
        if (code?.updated_at?.length > 0) {
            code.updated_at = new Date(code.updated_at);
        }
        set(() => ({code: code}));
    },
}));