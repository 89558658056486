import React, {useEffect, useState} from "react";
import QrScanner from "qr-scanner";
import {EndpointOrganizationGroups, EndpointOrganizationCodeCreate} from "@/constants/endpoints.constant";
import {useHttpGet, useHttpPost, useTranslation, useWorker} from "@/hooks";
import {useNewCodeStateStore, NewCodeStep} from "@/stores/newCodeState"
import {useAppStateStore} from "@/stores/keyforgeState.ts"
import {Code, Group, KeyForgeStateOrg, OrgInfo} from "@/types"
import {generateAlias, generateLabel, parseCodeUri} from "@/utils/codes"
import {Dropzone} from "@/components/molecules";
import {Button, Field, Label, Input, Select} from "@/components/atoms";
import {EncIdLength, OtpIdLength} from "@/constants";

type AddCodeProps = {
    orgUuid?: string
    groupUuid?: string
    onSuccess(code: Code): void
    onError(err: string): void
}

export const AddCode: React.FC<AddCodeProps> = ({orgUuid, groupUuid, onSuccess, onError}) => {
    const [worker] = useWorker();
    const {t} = useTranslation();
    const [org, setOrg] = useState<KeyForgeStateOrg | null>(null);
    const [orgInfo, setOrgInfo] = useState<OrgInfo>({} as OrgInfo);
    const [uri, setUri] = useState<string>("");
    const [orgKey, setOrgKey] = useState<string>("");
    const {code, step, setLabel, setAlias, setIssuer, setAccount, setStep, setOrganizationUuid, setGroupUuid, reset} = useNewCodeStateStore();
    const {getOrgs, getOrgByUuid} = useAppStateStore();
    const {execute: getGroups, data: groups, httpOrgKey} = useHttpGet(orgKey);
    const {execute: createCode} = useHttpPost(orgKey);
    const orgList = getOrgs();

    useEffect(() => {
        reset(); // reset state on load, ensure that we don't have any lingering state
    }, [])

    // sync input with code store
    useEffect(() => {
        if (org !== null) {
            return
        }
        let current = orgUuid;
        if (current === undefined) {
            current = (orgList?.length > 0) ? orgList[0].organization.uuid : "";
        }
        if (current !== code.organization_uuid) {
            setOrganizationUuid(current);
        }
    }, [orgUuid, orgList, code]);

    useEffect(() => {
        if (code.organization_uuid !== "") {
            // if org_uuid isn't set, we can't trust the group_uuid
            return
        }
        if (groupUuid !== code.group_uuid) {
            setGroupUuid(groupUuid || "");
        }
    }, [groupUuid]);

    // sync dep data
    useEffect(() => {
        if (!worker) {
            return;
        }
        if (code.organization_uuid) {
            const getOrg = getOrgByUuid(code.organization_uuid);
            setOrg(getOrg)
            if (getOrg) {
                setOrgKey(getOrg.organization.key)
                worker.GetOrgInfo(getOrg.organization.key).then(setOrgInfo)
            }
        }
    }, [code.organization_uuid, worker])

    useEffect(() => {
        if (org !== null && orgKey === httpOrgKey) { // verify orgKey has propagated
            getGroups(EndpointOrganizationGroups(org.organization.uuid), {}).then((result) => {
                if (code.group_uuid === "" && result?.items?.length > 0) {
                    setGroupUuid(result.items[0].uuid);
                }
            })
        }
    }, [org?.organization.uuid, org, httpOrgKey])

    const getGroupByUuid = (uuid: string) => {
        // TODO: If we later decide to not return all the encrypted data for the group in the list
        // then this call can just be updated to make an API call for that group's data
        return groups?.items?.find((group: Group) => group.uuid === uuid);
    }

    const addCode = async (e: any) => {
        e.preventDefault();
        if (!worker) {
            onError("Worker not ready")
            return
        }
        if (!org || !orgInfo) {
            onError("Failed to get org info")
            return
        }
        try {
            const group = getGroupByUuid(code.group_uuid);
            if (!group || group?.group_users?.length !== 1) {
                onError("Invalid group")
                return
            }
            const groupUserCreds = group.group_users[0].credentials
            const groupCreds = group.credentials
            const codeData = {
                uri: uri
            }
            const encId = await worker.RandomString(EncIdLength);
            const otpId = await worker.RandomString(OtpIdLength);

            const encryptedCodePayload = await worker.AddCode(orgKey, groupUserCreds, groupCreds, codeData)
            const newCode = await createCode(EndpointOrganizationCodeCreate(org.organization.uuid), {
                label: code.label,
                alias: code.alias,
                group_uuid: code.group_uuid,
                issuer: code.issuer,
                account: code.account,
                encrypted_value: encryptedCodePayload,
                enc_id: encId,
                otp_id: otpId,
            }, {})
            reset(); // reset new code state
            onSuccess(newCode);
        } catch (e: any) {
            onError(e.message)
        }
    }

    const handleFileUpload = async (uploadedFiles: Array<File>) => {
        const uploadedFile = uploadedFiles[0];
        const result = await QrScanner.scanImage(uploadedFile, {
            returnDetailedScanResult: true
        })
        setUri(result.data);

        // set data from uri
        const parsed = parseCodeUri(result.data)
        setLabel(generateLabel(parsed?.label || "", parsed?.issuer || ""));
        setAlias(generateAlias(parsed?.label || ""));
        setIssuer(parsed?.issuer || "");
        setAccount(parsed?.label || "");
        setStep(NewCodeStep.Config);
    }

    const renderUploadCode = () => {
        return (
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                {/*<img className="mx-auto h-10 w-auto"*/}
                {/*     src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"/>*/}
                <h2 className="mb-2 text-center text-2xl font-bold leading-9 tracking-tight">
                    Upload Code
                </h2>
                <Dropzone onDrop={handleFileUpload} enablePaste={true}/>
            </div>
        )
    }

    const renderConfigCode = () => {
        return (
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={addCode} method="POST">
                    {(orgList?.length || 0) > 1 && <div>
                        <Field>
                            <Label htmlFor="organization_uuid">{t("Organization")}</Label>
                            <Select name="organization_uuid" value={code.organization_uuid} onChange={(e) => setOrganizationUuid(e.target.value)}>
                                {orgList.map((org) => (
                                    <option key={org.organization.uuid} value={org.organization.uuid}>{org.organization.label}</option>
                                ))}
                            </Select>
                        </Field>
                    </div>}
                    {code.organization_uuid !== "" && !!groups && <div>
                        <Field>
                            <Label htmlFor="group_uuid">{t("Group")}</Label>
                            <Select name="group_uuid" value={code.group_uuid} onChange={(e) => setGroupUuid(e.target.value)}>
                                {groups?.items?.map((group: Group) => (
                                    <option key={group.uuid} value={group.uuid}>{group.label}</option>
                                ))}
                            </Select>
                        </Field>
                    </div>}
                    <div>
                        <label htmlFor="label" className="block text-sm font-medium leading-6">
                            {t("Your label")}
                        </label>
                        <div className="mt-2">
                            <Input id="label" name="label" type="text" required value={code.label} onChange={(e) => setLabel(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="alias" className="block text-sm font-medium leading-6">
                            {t("Your Alias")}
                        </label>
                        <div className="mt-2">
                            <Input id="alias" name="alias" type="text" required value={code.alias} onChange={(e) => setAlias(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="issuer" className="block text-sm font-medium leading-6">
                            {t("Issuer")}
                        </label>
                        <div className="mt-2">
                            <Input id="issuer" name="issuer" disabled={true} type="text" required value={code.issuer} onChange={(e) => setIssuer(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="account" className="block text-sm font-medium leading-6">
                            {t("Account")}
                        </label>
                        <div className="mt-2">
                            <Input id="account" name="account" disabled={true} type="text" required value={code.account} onChange={(e) => setAccount(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <Button type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            {t("Add Code")}
                        </Button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            {step === NewCodeStep.Upload && renderUploadCode()}
            {step === NewCodeStep.Config && renderConfigCode()}
        </div>
    );
};
