export const UrlHome = () => { return `/` };
export const UrlLogin = (currentOrgKey?: string, redirect?: string) => {
    let query: Array<string> = [];
    if (currentOrgKey) {
        query.push(`org_key=${currentOrgKey}`);
    }
    if (redirect) {
        query.push(`redirect_to=${encodeURIComponent(redirect)}`)
    }
    if (query.length > 0) {
        return `/login?${query.join("&")}`
    }
    return "/login"
};
export const UrlOrganizationAppConfigure = (orgKey: string, orgAppUuid: string) => { return `/organizations/${orgKey}/apps/${orgAppUuid}` };
export const UrlOrganizationApps = (orgKey: string) => { return `/organizations/${orgKey}/apps` };
export const UrlOrganizationBilling = (orgKey: string) => { return `/organizations/${orgKey}/billing` };
export const UrlOrganizationCreate = () => { return `/organizations/create` };
export const UrlOrganizationCreateCode = () => { return `/add-code` };
export const UrlOrganizationEditCode = (orgKey: string, codeUuid: string) => { return `/organizations/${orgKey}/codes/${codeUuid}` };
export const UrlOrganizationGroups = (orgKey: string) => { return `/organizations/${orgKey}/groups` };
export const UrlOrganizationGroup = (orgKey: string, groupUuid: string) => { return `/organizations/${orgKey}/groups/${groupUuid}` };
export const UrlOrganizationGroupsCreate = (orgKey: string) => { return `/organizations/${orgKey}/groups/create` };
export const UrlOrganizationSelect = () => {return `/organizations`};
export const UrlOrganizationSettings = (orgKey: string) => { return `/organizations/${orgKey}` };
export const UrlOrganizationUsers = (orgKey: string) => { return `/organizations/${orgKey}/users` };
export const UrlOrganizationUserUpdate = (orgKey: string, userUuid: string) => { return `/organizations/${orgKey}/users/${userUuid}` };
export const UrlOrganizationUsersInvite = (orgKey: string) => { return `/organizations/${orgKey}/users/invite` };
export const UrlSettings = () => { return `/settings` };
