import React, {useState} from "react";
import {useHttpPost, useTranslation} from "@/hooks";
import {EndpointOrganizationUserInvite} from "@/constants";
import {Organization} from "@/types";
import {Button} from "@/components/atoms/Button";
import {Input} from "@/components/atoms/Input";

type InviteUserProps = {
    organization: Organization
    onSuccess?(orgInvite: any): void
    onFailure?(err: Error): void
}

export const InviteUser: React.FC<InviteUserProps> = ({organization, onSuccess, onFailure}) => {
    const {t} = useTranslation();
    const {execute: inviteUser} = useHttpPost(organization.key);
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");

    const InviteUserHandler = async (e: any) => {
        e.preventDefault();

        try {
            const orgInvite = await inviteUser(EndpointOrganizationUserInvite(organization.uuid), {
                email: email,
                fullname: fullName,
            }, {})
            if (onSuccess) {
                onSuccess(orgInvite);
            }
        } catch (e: any) {
            if (onFailure) {
                onFailure(e.message)
            }
        }
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={InviteUserHandler} method="POST">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                            Email
                        </label>
                        <div className="mt-2">
                            <Input id="email" name="email" type="email" autoComplete="email" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="full_name" className="block text-sm font-medium leading-6">
                            Full Name</label>
                        <div className="mt-2">
                            <Input id="full_name" name="full_name" type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} required/>
                        </div>
                    </div>
                    <div>
                        <Button type="submit" color="primary" outline={false}
                                className="flex w-full justify-center">
                            {t("Invite")}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};
