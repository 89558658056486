export type OrgCreateInput = {
    orgKey: string;
    email: string;
    password: string;
    accountId: string;
    salt: string;
    version: string;
}

export type OrgCreateOutput = {
    encodedPublicKey: string;
    encryptedPrivateKey: string;
    privateKeyIv: string;
    secretKey: string;
}

export type GroupCreateOutput = {
    groupCreds: string;
    groupUserCreds: string;
}

export type LoginInput = {
    orgKey: string;
    secretKey: string;
    email: string;
    password: string;
    salt: string;
    iv: string;
    encodedPublicKey: string;
    encryptedPrivateKey: string;
};

export type LoginOutput = {

}

export type OrgInfo = {
    maskedSecretKey: string;
    identity: string,
}

export type OrgStoreRecord = {
    org_key: string;
    version: string;
    account_id: string;
    identity: string;
    salt: string; // salt bound to user account
    iv: string; // iv for decrypting payload
    encrypted_payload: string;
    private?: OrgStorePrivatePayload;
}

export type OrgStorePrivatePayload = {
    secret_key: string;
    auth_methods: Array<OrgAuthMethods>
}

export type OrgAuthMethods = {
    type: OrgAuthType;

    publicKey: string;
    privateKey: string;
    iv: string;
}

export type OrgAuthResult = {
    secretKey: string;
    publicKey: CryptoKey;
    privateKey: CryptoKey;
}

export enum OrgAuthType {
    Password = "password",
}

export enum WorkerCallbacks {
    OrgAuthRequest = "org-auth-request",
    OrgAuthUpdate = "org-auth-update",
}

export type AuthRequest = {
    orgKey: string;
    resolve: (value: boolean | PromiseLike<boolean>) => void;
    reject: (reason?: any) => void
}
