import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {clsx} from "clsx"
import {useTranslation} from "@/hooks";
import {
    Dialog,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@headlessui/react"
import {
    Bars3Icon,
    BellIcon,
    Cog6ToothIcon,
    PlusIcon,
    TableCellsIcon,
    UserCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";

import {Authenticate, BannerDisplay, CryptoStatus, NoticeOverlay} from "@/components/organisms";
import {Link, Logo, Button} from "@/components/atoms";
import {useCommStateStore} from "@/stores/commState.ts";
import {UrlOrganizationCreateCode, UrlOrganizationSelect, UrlSettings} from "@/constants"

const mainNavigation = [
    {name: "Search Codes", href: "/", icon: TableCellsIcon, current: false},
];

const quickActions = [
    {name: "Add Code", href: UrlOrganizationCreateCode(), icon: PlusIcon},
];

type LayoutMainProps = {};

export const LayoutMain: React.FC<LayoutMainProps> = () => {
    const {t} = useTranslation();
    const {authRequest} = useCommStateStore();
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const userNavigation = [
        {name: t("Manage Accounts"), href: UrlOrganizationSelect(), icon: Cog6ToothIcon, current: false},
        {name: t("Local Settings"), href: UrlSettings(), icon: PlusIcon},
    ];

    useEffect(() => {
        if (authRequest !== null) {
            openModal();
        }
    }, [authRequest]);

    const renderAuthenticator = () => {
        if (authRequest === null) {
            return null;
        }
        const handleConfirm = async () => {
            authRequest.resolve(true);  // Assume this is the function making the API call
            closeModal();
        };

        return (
            <Dialog open={isOpen} onClose={closeModal} className="fixed inset-0 z-10 overflow-y-auto">
                <div className="fixed inset-0 w-screen overflow-y-auto bg-black bg-opacity-80">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="relative p-4 bg-gray-400 rounded max-w-md mx-auto">
                            <Authenticate orgKey={authRequest.orgKey} onSuccess={handleConfirm} onError={closeModal}/>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    return (
        <div className="bg-gradient-to-br from-primary-25 via-secondary-50 to-primary-25 dark:from-primary-700 dark:via-primary-900 dark:to-primary-700">
            <BannerDisplay/>
            <Disclosure as="nav" className="">
                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 bg-white dark:bg-primary-700 border-primary-100 dark:border-primary-900 rounded-b-lg shadow-sm border border-t-0">
                    <div className="flex h-16 justify-between">
                        <div className="flex">
                            <div className="-ml-2 mr-2 flex items-center md:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5"/>
                                    <span className="sr-only">Open main menu</span>
                                    <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden"/>
                                    <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block"/>
                                </DisclosureButton>
                            </div>
                            <div className="flex flex-shrink-0 items-center">
                                <Logo/>
                            </div>
                            <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                                {mainNavigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        aria-current={item.current ? 'page' : undefined}
                                        className={clsx(
                                            item.current ? 'bg-primary-300 text-white' : 'text-primary-700 dark:text-primary-100 hover:bg-primary-300 dark:hover:bg-primary-500 hover:text-white',
                                            'rounded-md px-3 py-2 text-sm font-medium',
                                        )}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <CryptoStatus />
                            <div className="flex-shrink-0 hidden md:flex">
                                {quickActions.map((action) => (
                                    <Link
                                        key={action.name}
                                        to={action.href}
                                        className="relative inline-flex items-center gap-x-1.5 rounded-md bg-secondary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 shadow-md"
                                    >
                                        {action.name}
                                        <PlusIcon className="w-4"/>
                                    </Link>
                                ))}
                            </div>
                            <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-1.5"/>
                                            <span className="sr-only">{t("Open user menu")}</span>
                                            <UserCircleIcon className="h-8 w-8 rounded-full text-white"/>
                                        </MenuButton>
                                    </div>
                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        {userNavigation.map((item) => (
                                            <MenuItem key={item.name}>
                                                <Link to={item.href} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                                                    {item.name}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </MenuItems>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>

                <DisclosurePanel className="md:hidden bg-white text-primary-700 dark:bg-primary-900 dark:text-primary-100">
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {mainNavigation.map((item) => (
                            <DisclosureButton
                                key={item.name}
                                as="a"
                                href={item.href}
                                aria-current={item.current ? 'page' : undefined}
                                className={clsx(
                                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'block rounded-md px-3 py-2 text-base font-medium',
                                )}
                            >
                                {item.name}
                            </DisclosureButton>
                        ))}
                    </div>
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {quickActions.map((action) => (
                            <Button
                                to={action.href}
                                key={action.name}
                                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                {action.name}
                                <PlusIcon/>
                            </Button>
                        ))}
                    </div>
                    <div className="border-t border-gray-700 pb-3 pt-4">
                        <div className="flex items-center px-5 sm:px-6">
                            <div className="ml-3">
                                <div className="text-base font-medium text-white">Placeholder Name</div>
                                <div className="text-sm font-medium text-gray-400">someone@fake.net</div>
                            </div>
                            <button
                                type="button"
                                className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                            >
                                <span className="absolute -inset-1.5"/>
                                <span className="sr-only">View notifications</span>
                                <BellIcon aria-hidden="true" className="h-6 w-6"/>
                            </button>
                        </div>
                        <div className="mt-3 space-y-1 px-2 sm:px-3">
                            {userNavigation.map((item) => (
                                <DisclosureButton
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                >
                                    {item.name}
                                </DisclosureButton>
                            ))}
                        </div>
                    </div>
                </DisclosurePanel>
            </Disclosure>

            {renderAuthenticator()}

            <div className="h-full text-white">
                <main>
                    <div className="h-full min-h-screen p-4">
                        <Outlet/>
                    </div>
                </main>
            </div>
            <NoticeOverlay/>
        </div>
    );
}