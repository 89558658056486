import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {InformationCircleIcon} from "@heroicons/react/24/solid";

type TooltipLabelProps = {
    className?: string
    children: React.ReactNode
    label: string
    leftMargin?: number
    [key: string]: any;
}

// const MouseEventThrottle = 25;

export const TooltipLabel: React.FC<TooltipLabelProps> = ({className, label, children, leftMargin = 20, ...rest}) => {
    const containerRef = useRef<HTMLLabelElement>(null);
    const tooltipRef = useRef<HTMLSpanElement>(null);
    const [tooltipStyles, setTooltipStyles] = useState({ left: 0, top: 0 });
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const lastMouseUpdate = useRef<number>(Date.now());

    // Throttle the mouse position updates (10 events per second or 100ms)
    const handleMouseMove = (e: React.MouseEvent<HTMLLabelElement>) => {
        // if (Date.now() - lastMouseUpdate.current < MouseEventThrottle) {
        //     return
        // }
        setMousePosition({x: e.clientX, y: e.clientY});
        lastMouseUpdate.current = Date.now();
    }

    useEffect(() => {
        if (!containerRef.current || !tooltipRef.current) return;

        let newWidth: number | undefined = undefined;
        const container = containerRef.current.getBoundingClientRect();
        const tooltip = tooltipRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Calculate initial position, centered on cursor
        let x = (mousePosition.x - container.x + (tooltip.width / 2) + leftMargin)
        let y = mousePosition.y - container.y;

        // Vertical adjustments
        if (tooltip.top < 0) {
            y = mousePosition.y - container.y + tooltip.height / 2; // Below the cursor
        } else if (tooltip.bottom > viewportHeight) {
            y = mousePosition.y - container.y - tooltip.height / 2; // Above the cursor
        }

        // Horizontal adjustments
        if (tooltip.right > viewportWidth) {
            const availableWidth = viewportWidth - mousePosition.x - leftMargin;
            newWidth = Math.max(tooltip.width * 0.5, availableWidth);

            if (newWidth < tooltip.width * 0.5) {
                x = mousePosition.x - container.x - newWidth / 2; // Move tooltip left
            }
        } else if ((tooltip.width / 2) > mousePosition.x) {
            // Reduce width to fit within the viewport on the left side
            newWidth = viewportWidth - (leftMargin * 2);
        }

        // Setting newWidth only if defined
        setTooltipStyles({
            left: x,
            top: y,
            ...(newWidth !== undefined && { width: `${newWidth}px` }), // conditionally assign width if set
        });
    }, [mousePosition, leftMargin]);

    return (
        <label {...rest} onMouseMove={handleMouseMove} ref={containerRef} className="relative hover:cursor-pointer group pl-2 inline text-sm font-medium leading-6">
            {label}
            <InformationCircleIcon className="h-5 w-5 inline-block"/>
            <span ref={tooltipRef} className={clsx("absolute hidden group-hover:block p-4 z-50 rounded-lg bg-white shadow-md ring-1 ring-black-300 shadow-gray-200 dark:shadow-gray-600 w-auto max-w-[50vw] text-nowrap bg-[#f68600] text-white", className)} style={{
                ...tooltipStyles,
                pointerEvents: "none",
                transform: "translate(-50%, -50%)",
            }}>
                {children}
            </span>
        </label>
    );
}