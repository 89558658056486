import React, {useEffect} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {EndpointOrganizationApps, EndpointOrganizationAppCreate, UrlOrganizationAppConfigure, BlankUuid} from "@/constants"
import {useHttpGet, useHttpPut, useTranslation} from "@/hooks";
import {App, OrganizationAppStatus, OrgOutlet} from "@/types";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {Link} from "@/components/atoms";

type PageOrganizationAppsProps = {}

export const PageOrganizationAppsList: React.FC<PageOrganizationAppsProps> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {organization} = useOutletContext<OrgOutlet>();
    const {addNotice} = useAppStateStore();
    const {execute: getApps, data: apps} = useHttpGet(organization?.key);
    const {execute: createApp} = useHttpPut(organization?.key);

    useEffect(() => {
        getAppList()
    }, [])

    const getAppList = async () => {
        if (!organization?.key) {
            return;
        }
        getApps(EndpointOrganizationApps(organization.uuid), {})
    }

    const handleCreateApp = (appId: string) => {
        console.log({app_id: appId});
        createApp(EndpointOrganizationAppCreate(organization.uuid), {app_id: appId}, {}).then((result) => {
            addNotice({type: "notify", style: "success", message: t("App created successfully")})
            navigate(`./${result.uuid}`);
        }).catch((err) => {
            addNotice({type: "notify", style: "error", message: err.message || t("Failed to create app")})
        })
    }

    const getAppStatusLabel = (status: string): string => {
        switch (status) {
            case OrganizationAppStatus.Installed:
                return t("Ready");
            case OrganizationAppStatus.NotAuthorized:
                return t("Action needed: Authorization required")
            case OrganizationAppStatus.Uninstalled:
                return t("Not installed");
            default:
                return "";
        }
    }

    // return (
    //     <div className="text-white">
    //         { loading && <p>Loading...</p> }
    //         <div>
    //             {apps?.items?.map((app: any) => (
    //                 <div key={app.id}>
    //                     {app.label}
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // );

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6">{t("Apps")}</h1>
                    <p className="mt-2 text-sm ">
                        {t("A list of all available Key Forge applications to access your codes.")}
                    </p>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                                    {t("App name")}
                                </th>
                                <th scope="col" className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold">
                                    {t("Status")}
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {apps?.items?.map((app: App) => {
                                const orgApp = app.organization_app;
                                return (
                                    <tr key={app.id}>
                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                            <div className="flex items-center">
                                                {/*<div className="h-11 w-11 flex-shrink-0">*/}
                                                {/*    <img alt="" src={person.image} className="h-11 w-11 rounded-full" />*/}
                                                {/*</div>*/}
                                                <div className="ml-4">
                                                    <div className="font-medium ">{app.label}</div>
                                                    {/*<div className="mt-1 text-gray-500">{app?.status}</div>*/}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                            {getAppStatusLabel(orgApp.status)}
                                        </td>
                                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <Link
                                                to={(orgApp?.uuid === undefined || orgApp?.uuid === BlankUuid) ? "#" : UrlOrganizationAppConfigure(organization?.key, orgApp.uuid)}
                                                onClick={(e) => {
                                                    if (orgApp.status === OrganizationAppStatus.Uninstalled) {
                                                        e.preventDefault();
                                                        handleCreateApp(app.id)
                                                        return;
                                                    }
                                                    // otherwise don't do anything and let the anchor navigate as it normally would
                                                }} className="text-primary-300 dark:text-secondary-100 hover:text-primary-100 dark:hover:text-secondary-50 hover:underline">
                                                {orgApp.status === OrganizationAppStatus.Uninstalled ? t("Install") : t("Configure")}<span className="sr-only">, {app.label}</span>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
