import {CodeAccess, ParsedUri} from "@/types"
import parseURI from "otpauth-uri-parser";
import {TOTP} from "totp-generator"

type ValidAlgorithms = "SHA-1" | "SHA-224" | "SHA-256" | "SHA-384" | "SHA-512" | "SHA3-224" | "SHA3-256" | "SHA3-384" | "SHA3-512"
const DefaultAlg = "SHA-1" as ValidAlgorithms;

export const parseCodeUri = (uri: string): ParsedUri | null => {
    try {
        const parsed = parseURI(uri);
        return {
            label: parsed.label.account,
            issuer: parsed?.query?.issuer ? parsed.query.issuer : (parsed?.label?.issuer || ""),
        };
    } catch (error) {
        console.error("Failed to parse URI:", error);
        return null;
    }
}

export const generateLabel = (codeLabel: string, codeAccount: string): string => {
    return `${codeLabel.replace(/[^A-Za-z0-9\s-]/g, "")} (${codeAccount.replace(/[^A-Za-z0-9\s@-_.]/g, "")})`;
}

export const generateAlias = (codeLabel: string): string => {
    // replace non alnum characters and replace spaces with hyphens, and double hyphens with single hyphens
    return codeLabel.toLowerCase().replace(/[^A-Za-z0-9\s-]/g, "").replace(/\s/g, "-").replace(/-+/g, "-");
}

export const generateTotpKey = (uri: string, timestamp?: number): CodeAccess => {
    const parsed = parseURI(uri);
    let digits: number = 6;
    let alg: string = "";
    let period: number = 30;

    if (!timestamp) {
        timestamp = new Date().getTime();
    }
    if (!!parsed?.query?.digits && parsed.query.digits != 6) {
        digits = parsed.query.digits as number;
    }
    if (!!parsed?.query?.algorithm && parsed.query.algorithm != alg) {
        alg = parsed.query.algorithm as string;
    }
    alg = normalizeAlgorithm(alg);
    if (!!parsed?.query?.period && parsed.query.period != period) {
        period = parsed.query.period as number
    }

    const {otp} = TOTP.generate(parsed.query.secret, {
        digits: digits,
        algorithm: alg as ValidAlgorithms,
        period: period,
        timestamp: timestamp,
    })

    const periodMs = period * 1000;
    return {
        otp: otp,
        nextAt: Math.ceil(timestamp / periodMs) * periodMs,
        period: period,
    }
}

const normalizeAlgorithm = (alg: string): ValidAlgorithms => {
    if (!alg) {
        return DefaultAlg;
    }
    if (!alg.includes("-")) {
        if (alg.startsWith("SHA3") && alg.length > 6) {
            alg = "SHA3-" + alg.slice(4);
        } else {
            alg = "SHA-" + alg.slice(3);
        }
    }
    // if it already contains a hyphen, there isn't anything to normalize
    return alg as ValidAlgorithms;
}