import React from "react";
import {ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon} from "@heroicons/react/20/solid";

export type Notice = {
    type: "notify" | "modal" | "banner"; // Presentation method
    style: "warning" | "error" | "success" | "default"; // style
    message?: string; // The message to convey to the user
    content?: React.ReactNode;
    actions?: Array<NoticeAction>; // Other than dismiss, any function you want to give the user the ability to trigger.
    easyDismiss?: boolean; // Default true; When enabled, notifys expire in 15s and modals can background click to close. Disabling requires the user to click the button to dismiss.
    timestamp?: string; // When alert was created
    organization_uuid?: string; // Cooresponding Org the alert is about. Should remain empty if it's a user or global alert
}

export type NoticeAction = {
    name: string; // What to display on the button. Keep it short.
    func(): void; // Pass-thru the function to trigger when the button is clicked.
}

export type NoticeStyle = {
    icon: JSX.Element,
    header: string,
    body: string,
    button: string,
    background: string
}

export const noticeStyles: {[key: string]: NoticeStyle} = {
    warning: {
        icon: <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-secondary-300" />,
        header: "text-secondary-900",
        body: "text-secondary-900",
        button: "bg-secondary-500 text-secondary-900 hover:bg-secondary-100 focus:ring-secondary-500 focus:ring-offset-secondary-100",
        background: "bg-secondary-300"
    },
    error: {
        icon: <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-secondary-900" />,
        header: "text-secondary-800",
        body: "text-secondary-900",
        button: "bg-secondary-500 text-secondary-900 hover:bg-secondary-100 focus:ring-secondary-700 focus:ring-offset-secondary-100",
        background: "bg-secondary-700"
    },
    success: {
        icon: <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-primary-500" />,
        header: "text-primary-700",
        body: "text-primary-700",
        button: "bg-primary-50 text-primary-700 hover:bg-primary-100 focus:ring-primary-500 focus:ring-offset-primary-50",
        background: "bg-primary-100"
    },
    default: {
        icon: <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-primary-900" />,
        header: "text-primary-900",
        body: "text-primary-900",
        button: "bg-primary-50 text-primary-900 hover:bg-primary-100 focus:ring-primary-700 focus:ring-offset-primary-50",
        background: "bg-primary-50"
    },
};