import React from "react";
import {clsx} from 'clsx';
import {type InputProps as HeadlessInputProps} from '@headlessui/react';
import {Input, InputType} from "@/components/atoms/Input";

interface InputFieldProps {
    className?: string;
    error?: string;
}

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps & { type?: InputType } & HeadlessInputProps>(
    ({className, error, ...props}, ref) => {
        return (
            <>
                <Input ref={ref} className={clsx(className)} data-invalid={!!error && error != "" ? true : undefined} {...props} />
                { !!error && <span className="block">{error}</span> }
            </>
        );
    }
);