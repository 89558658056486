import { useAppStateStore } from "@/stores/keyforgeState"
import * as Endpoints from "@/constants/endpoints.constant.ts";
import { UrlLogin } from "@/constants/paths.constant.ts";
export enum HttpMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Patch = 'PATCH',
    Delete = 'DELETE',
}
export const HttpMethodsWithBody = [HttpMethod.Post, HttpMethod.Put, HttpMethod.Patch];
export type RequestOptions = {
    authToken?: string;
    excludeAuthHeader?: boolean;
    retryOnAuthError?: boolean;
    credentials?: RequestCredentials;
    role?: string;
    headers?: Record<string, string>;
    host?: string;
    isJSON?: boolean;
    body?: object | string | undefined;
};

export type ExecuteFunction<T = any> = (endpoint: string, options: RequestOptions | undefined) => Promise<T>;
export type ExecuteBodyFunction<T = any> = (endpoint: string, body: any, options: RequestOptions | undefined) => Promise<T>;

export type UseHttpResponse<T = any, E = ExecuteFunction<T> | ExecuteBodyFunction<T>> = {
    httpOrgKey: string;
    status: number;
    loading: boolean;
    error: string | null;
    data: T | null;
    execute: E;
};

export type Pagination = {
    page: number;
    limit: number;
    start: number;
    total_rows: number;
    total_pages: number;
}

export type ListResponse<T> = {
    items: T[];
    pagination: Pagination;
}

const HEADER_X_KEY_FORGE_ROLE = "X-Key-Forge-Role";
const HEADER_X_KEY_FORGE_APP_ID = "X-KeyForge-Appid";
const WEB_APP_ID = "web";
const HTTP_STATUS_UNAUTHORIZED = 401;
const DEFAULT_HOST = "https://api.keyforge.io";

export const buildOptions = (options?: RequestOptions): RequestOptions => {
    if (!options) {
        options = {};
    }
    const defaultOptions = {
        host: DEFAULT_HOST,
        excludeAuthHeader: false,
        role: '',
        headers: {},
        retryOnAuthError: true,
        isJSON: true,
    };
    if (!options?.headers) {
        options.headers = {};
    }
    if (options?.excludeAuthHeader === true && options.retryOnAuthError === undefined) {
        options.retryOnAuthError = false; // if excluding the auth header, and no option was set for retry, assume no retry should happen
    }
    return { ...defaultOptions, ...options } as RequestOptions;
};

export const redirectToLogin = (currentOrg?: string) => {
    const currentUrl = window.location.pathname;
    window.location.href = UrlLogin(currentOrg, currentUrl);
};

const makeRequest = async (method: HttpMethod, host: string, endpoint: string, options: RequestOptions): Promise<Response> => {
    let { role, headers } = options;
    headers = headers || {};

    host = host || DEFAULT_HOST; // default to the production host, but should generally be set before now
    if (window.location.hostname === 'web.local.keyforge.io') {
        if (host === DEFAULT_HOST) {
            host = "https://api.local.keyforge.io";
        }
    }
    if (host[host.length - 1] === '/') {
        // All endpoints should come from endpoints.constant, which should all be prefixed with a / already
        host = host.substring(0, host.length - 1);
    }

    const url = `${host}${endpoint}`;

    if (!options.excludeAuthHeader && options.authToken) {
        headers.Authorization = `Bearer ${options.authToken}`;
    }
    if (role) {
        headers[HEADER_X_KEY_FORGE_ROLE] = role;
    }
    headers[HEADER_X_KEY_FORGE_APP_ID] = WEB_APP_ID;
    const requestData: RequestInit = {
        method,
        headers,
    };
    if (options.credentials) {
        requestData.credentials = options.credentials;
    } else {
        requestData.credentials = "omit"
    }
    if (options.body) {
        requestData.body = options.body as any;
    }
    return fetch(url, requestData);
};

export class FetchRequest {
    private method: HttpMethod
    private host: string
    private refreshPromise: Promise<string> | null = null
    // private appStateSubscription: () => void
    private orgKey?: string

    constructor(method: HttpMethod, orgKey?: string) {
        this.method = method;
        this.host = DEFAULT_HOST;
        if (orgKey) {
            this.subscribeToOrg(orgKey);
        }
    }

    destroy() {
        // if there is an subscription for the orgKey, unsubscribe
        // if (this.appStateSubscription) {
        //     this.appStateSubscription();
        // }
    }

    subscribeToOrg(orgKey: string) {
        // check if there is an existing listener, if so, unsubscribe
        // if (this.appStateSubscription) {
        //     this.appStateSubscription();
        // }
        this.orgKey = orgKey;
        if (orgKey == "") {
            return; // no need to subscribe to an empty orgKey
        }
        // const orgCreds = useAppStateStore.getState().getOrgByKey(orgKey)?.creds;
        // if (orgCreds) {
        //     this.authToken = orgCreds.creds?.access_token;
        // }
        // this.appStateSubscription = useAppStateStore.subscribe((state) => {
        //     const orgCreds = state.getOrgByKey(orgKey)?.creds;
        //     if (orgCreds) {
        //         this.authToken = orgCreds.creds?.access_token;
        //     }
        // })
    }

    getOrgKey(): string {
        return this.orgKey || "";
    }

    setOrgKey(orgKey: string) {
        // subscribe to this orgKey, it will set authToken upon listening or change
        this.subscribeToOrg(orgKey);
    }

    async makeWithRetries(endpoint: string, options: RequestOptions): Promise<Response> {
        let response = await this.makeRequest(endpoint, options);
        if (response.status === HTTP_STATUS_UNAUTHORIZED && options.retryOnAuthError) {
            if (options.excludeAuthHeader) {
                console.log('API requires auth, but auth was disabled. Possible implementation problem');
                return response;
            }
            await this.refreshToken();
            response = await this.makeRequest(endpoint, options);
        }
        return response;
    }

    async refreshToken() {
        if (this.refreshPromise !== null) {
            return this.refreshPromise;
        }
        this.refreshPromise = this.refreshTokenProcess();
        return this.refreshPromise.then(() => {
            this.refreshPromise = null;
        }).catch(() => {
            this.refreshPromise = null;
            redirectToLogin(this.orgKey);
        })
    };

    async refreshTokenProcess(): Promise<string> {
        try {
            const response = await makeRequest(HttpMethod.Post, this.host, Endpoints.EndpointAuthRefresh(), buildOptions({
                excludeAuthHeader: true,
                credentials: 'include',
                body: JSON.stringify({
                    org_key: this.orgKey
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }));
            const data = await response.json();
            if (response.status === 200) {
                useAppStateStore.getState().setAuthUserAndCreds(this.orgKey!, data.user, {
                    access_token: data.access_token,
                    expiration: data.expiration
                });
                return data.access_token;
            }
            return Promise.reject(new Error('Auth refresh request failed'));
        } catch (e) {
            return Promise.reject(e);
        }
    };

    async makeRequest(endpoint: string, options: RequestOptions): Promise<Response> {
        if (!options.excludeAuthHeader && this.orgKey) {
            const orgCreds = useAppStateStore.getState().getOrgByKey(this.orgKey)?.creds;
            if (orgCreds) {
                options.authToken = orgCreds?.access_token || "";
            }
        }
        let host = options.host || DEFAULT_HOST; // default to the production host, but should generally be set before now
        return makeRequest(this.method, host, endpoint, options);
    };

    // methods when using this class outside of hooks
    async execute(endpoint: string, options: RequestOptions | {}): Promise<Response> {
        options = buildOptions(options);
        return this.makeWithRetries(endpoint, options);
    }

    async executeBody(endpoint: string, body: any, options: RequestOptions | undefined): Promise<Response> {
        options = buildOptions(options);
        if (body) {
            if (options.isJSON) {
                options.body = JSON.stringify(body);
                options.headers!['Content-Type'] = 'application/json';
            } else {
                options.body = body;
            }
        }
        return this.execute(endpoint, options);
    }
}