import React, {useCallback, useState} from "react";
import {useDebounceCallback} from "usehooks-ts";
import {useNewOrgStore} from "@/stores/newOrgState.ts";
import {UrlLogin, EndpointOrganizationCreateCheckKey} from "@/constants";
import {useHttpGet, useTranslation} from "@/hooks";
import {Button, InlinePlanSelector, Input, Link, TooltipLabel} from "@/components/atoms";


interface OrganizationProps {
    onSubmit: () => void;
}

const CreateOrganization: React.FC<OrganizationProps> = ({onSubmit}) => {
    const {t} = useTranslation();
    const {execute: checkKey} = useHttpGet();
    const [orgKeyError, setOrgKeyError] = useState<string | undefined>(undefined);
    const checkOrgKeyAvailability = useCallback(async (key: string) => {
        if (key.length === 0) {
            setOrgKeyError(undefined);
            return
        }
        const result = await checkKey(EndpointOrganizationCreateCheckKey(key), {excludeAuthHeader: true, });
        setOrgKeyError(!result.available ? t("This workspace ID is already taken") : undefined);
    }, [])
    const debouncedKeyCheck = useDebounceCallback(checkOrgKeyAvailability, 250)
    const {newOrgState: {org_key, org_label, org_plan, org_billing_email}, setOrgKey, setOrgLabel, setOrgPlan, setOrgBillingEmail} = useNewOrgStore();

    const registerOrganizationHandler = async (e: any) => {
        e.preventDefault();
        // validate data
        // if all good, call onSubmit
        onSubmit();
    }

    const setOrgKeyhandler = (key: string) => {
        debouncedKeyCheck(key);
        setOrgKey(key);
    }

    const setLabel = (e: any) => {
        const label = e.target.value;
        setOrgLabel(label);
        setOrgKey(label.toLowerCase().trim().replace(/\s/g, "-").replace("/\-\-/g", "-"));
    }

    return (
        <div>
            <div className="">
                <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight">
                    {t("Create new organization")}
                </h2>
                <p className='text-md mt-4'>
                    {t("Fill out the form below to create a new organization for yourself or a group of people. If you are looking to join an existing organization, please contact your Administrator to be invited to an existing account.")}
                </p>
            </div>
            <div className="mt-10">
                <form className="space-y-6" onSubmit={registerOrganizationHandler} method="POST">
                    <div>
                        <label htmlFor="fullname" className="block text-sm font-medium leading-6">
                            {t("Organization Name")}
                        </label>
                        <div className="mt-2">
                            <Input id="fullname" name="fullname" type="input" value={org_label} onChange={setLabel}/>
                        </div>
                    </div>
                    <div>
                        <TooltipLabel htmlFor="org_key" label={t("Workspace ID")}>{t("Workplace ID is a unique identifier used for your organization.")}</TooltipLabel>
                        <div className="mt-2">
                            <Input
                                id="org_key"
                                name="org_key"
                                type="input"
                                data-invalid={orgKeyError}
                                value={org_key}
                                onChange={(e: any) => {
                                    setOrgKeyhandler(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium leading-6">
                            {t("Plan")}
                        </label>
                        <div className="mt-2">
                            <InlinePlanSelector value={org_plan} onChange={setOrgPlan} />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                            {t("Billing Email")}
                        </label>
                        <div className="mt-2">
                            <Input id="email" name="email" type="email" value={org_billing_email} onChange={(e: any) => {
                                setOrgBillingEmail(e.target.value)
                            }} required/>
                        </div>
                    </div>
                    <div>
                        <Button type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            {t("Next Step: Create Account")}
                        </Button>
                    </div>
                    <p className="mt-10 text-center text-sm text-gray-400">
                        <Link to={UrlLogin()} className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">Already have an account?</Link>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default CreateOrganization;



