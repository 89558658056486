import React, { ReactNode } from 'react';
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

// Enum for Admonition types
export enum AdmonitionType {
    Note = "note",
    Tip = "tip",
    Info = "info",
    Warning = "warning",
    Danger = "danger"
}

type AdmonitionProps = {
    type: AdmonitionType;
    children: ReactNode;
    Icon?: React.ElementType; // Icon component is optional
}

export const Admonition: React.FC<AdmonitionProps> = ({ type, children, Icon = ExclamationTriangleIcon }: AdmonitionProps) => {
    const typeStyles = {
        note: { borderColor: 'primary-300', backgroundColor: 'primary-100', textColor: 'primary-700' },
        tip: { borderColor: 'green-400', backgroundColor: 'green-50', textColor: 'green-700' },
        info: { borderColor: 'gray-400', backgroundColor: 'gray-50', textColor: 'gray-700' },
        warning: { borderColor: 'yellow-400', backgroundColor: 'yellow-50', textColor: 'yellow-700' },
        danger: { borderColor: 'red-400', backgroundColor: 'red-50', textColor: 'red-700' },
    }[type];

    return (
        <div className={`border-l-4 border-${typeStyles.borderColor} bg-${typeStyles.backgroundColor} p-4 mt-2 mb-2`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <Icon className={`h-5 w-5 text-${typeStyles.borderColor}`} aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <p className={`text-sm text-${typeStyles.textColor}`}>
                        {children}
                    </p>
                </div>
            </div>
        </div>
    );
}
