import React, {useEffect, useRef, useState} from "react";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/react"
import clsx from "clsx";
import {ClipboardIcon, EyeIcon, EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {useHttpGet, useTranslation, useWorker} from "@/hooks";
import {useCommStateStore} from "@/stores/commState.ts";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {EndpointOrganizationCodeGetOne} from "@/constants/endpoints.constant.ts";
import {UrlOrganizationEditCode} from "@/constants";
import {Code, CodeAccess} from "@/types";
import {CodeTimer, Link} from "@/components/atoms"

type CodeCardProps = {
    code: any
}

export const CodeCard: React.FC<CodeCardProps> = ({code}) => {
    const [worker] = useWorker();
    const {t} = useTranslation();
    const {addNotice} = useAppStateStore();
    const {unlockedOrgs} = useCommStateStore();
    const [loadedCode, setLoadedCode] = useState<Code | null>(null);
    const {execute: getCode} = useHttpGet(code.organization.key);
    const [otpCodeAccess, setOtpCodeAccess] = useState<CodeAccess | null>(null);
    const [ codeReveal, setCodeReveal ] = useState(false);
    const [ copyText, setCopyText ] = useState("Copy"); // Manage Copy button language
    const codeCacheTimer = 30000; // How long to store cached codes in state
    const codeCacheTimeoutRef = useRef<any>(0); // Timeout Reference to trigger codeCache purge
    const codeDisplayTimeoutRef = useRef<any>(0); // Timeout Reference to deactivate code display for Reveal
    const codeRefreshTimeoutRef = useRef<any>(0);

    useEffect(() => {
        // TODO: Update this to check the code's organization key against the unlockedOrgs list
        // for now, this will just handle global locking
        if (unlockedOrgs.length === 0) {
            hideCode();
        }
    }, [unlockedOrgs])

    // if code is hidden, clear the timeout
    useEffect(() => {
        if (!codeReveal) {
            clearTimeout(codeRefreshTimeoutRef.current); // clearTimeout just in case it's set since the card is no longer active
        }
    }, [codeReveal])

    const getCodeData = async (): Promise<CodeAccess> => {
        // do something with fetchCode()
        if (!worker) {
            return Promise.reject();
        }
        let codeData = loadedCode;
        if (!codeData) {
            codeData = await getCode(EndpointOrganizationCodeGetOne(code.organization_uuid, code.uuid), {});
            setLoadedCode(codeData);
        }
        if (!codeData) {
            throw new Error("No code data found");
        }

        codeCacheTimeoutRef.current = setTimeout(()=>{
            setCodeReveal(false);
            // we need a small buffer to allow the animation of hiding the code before we purge the cache of it
            setTimeout(()=>{
                setLoadedCode(null);
            }, 300);
        }, codeCacheTimer);

        const groupCreds = codeData.group.credentials;
        const groupUserCreds = codeData.group.group_users[0].credentials;

        return await worker.GetCode(code.organization.key, groupUserCreds, groupCreds, code.encrypted_value);
    }

    const startCodeRefreshTimer = (nextAt: number) => {
        const timeout = nextAt - (new Date().getTime());
        codeRefreshTimeoutRef.current = setTimeout(() => {
            getCodeData().then((codeAccess) => {
                setOtpCodeAccess(codeAccess);
                startCodeRefreshTimer(codeAccess.nextAt);
            }).catch(() => {
                addNotice({
                    style: "error",
                    type: "notify",
                    message: t("Failed to get code")
                });
                setOtpCodeAccess(null);
                setCodeReveal(false);
            });
        }, timeout);
    }

    const hideCode = () => {
        // Clear the close timer if it's set
        if (codeDisplayTimeoutRef.current) {
            clearTimeout(codeDisplayTimeoutRef.current);
        }
        // Set active to false
        setCodeReveal(false);
    }

    const handleCopy = async () => {
        const codeAccess = await getCodeData();
        navigator.clipboard.writeText(codeAccess.otp);

        setCopyText(t("Copied"));

        // Reset the copy just long enough after the user can read the notice
        setTimeout(()=>{
            setCopyText(t("Copy"))
        }, 5000);
    }

    const handleReveal = async () => {
        if (codeReveal) {
            hideCode();
        } else {
            try {
            
              const codeAccess = await getCodeData();
              setOtpCodeAccess(codeAccess);
  
              // Otherwise, the toggle is closed and we want to display the code
              setCodeReveal(true);
  
              // // refresh code automatically until hidden
              startCodeRefreshTimer(codeAccess.nextAt);
              //
              // // Set a timer to hide the code, there's a redundant call in the code cache expiration timer to ensure we're not displaying old codes
              codeDisplayTimeoutRef.current = setTimeout(()=>{
                  setCodeReveal(false);
              }, codeCacheTimer);
            } catch (error) {
              console.log(error)
            }
        }
    }

    const matchStyles = (match: boolean) => {
        if (match) {
            return "bg-white"
        } else {
            return "bg-gray-300"
        }
    }

    return (
      <div className={clsx(["w-full divide-y divide-gray-200 transition-all duration-1000  border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500", matchStyles(code.match)])}>
        {/* Code Actions and Metadata */}
        <div className="py-3 pl-6">
            <div className="grid grid-rows-3 grid-cols-6 grid-flow-col">
              {/* Code Label */}
              <div className="row-span-2 col-span-5 ">
                <h3 className="text-ellipsis overflow-hidden h-10 text-sm font-medium">{code.label}</h3>
              </div>
              <div className="row-span-1 col-span-5">
                {/* Code Organization */}
                <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {code.organization.label}
                </span>
                {code.group && <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {code.group.label}
                </span>}
              </div>
              <div className="row-span-3">
                {/* Code Action Menu */}
                <Menu as="div" className="relative ml-3 place-content-end">
                  <div>
                    <MenuButton className="relative flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open code menu</span>
                        <EllipsisVerticalIcon 
                          className="h-7 w-7 flex-shrink-0" />
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                      <Link to={UrlOrganizationEditCode(code.organization.key, code.uuid)} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                          {t("Manage")}
                      </Link>
                      </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
          </div>
        </div>
        {/* Code Retrieval and Display */}
        <div className="bg-transparent">
          <div className="-mt-px flex divide-x divide-gray-200 dark:divide-primary-900">
            <div className="flex w-0 flex-1">
              <div className="relative inline-flex items-center justify-center p-2 h-16 overflow-hidden font-medium text-indigo-600 container text-balance">
                <div className={clsx(["bg-transparent p-2 absolute flex items-center justify-center text-center w-full h-full bg-primary-50 dark:bg-primary-900 text-primary-500 dark:text-primary-100 duration-500 transition-all transform ease z-10", {"translate-x-full":codeReveal}, matchStyles(code.match)])} >
                  { code.alias }
                </div>
                <div className="absolute flex items-center inset-0 justify-center w-full h-full text-white bg-indigo-500 font-mono z-0 shadow-inner shadow-slate-600 text-3xl">
                        { otpCodeAccess?.otp ? 
                          <div className="grid grid-cols-4 gap-0 items-center">
                            <div className="col-span-2 col-start-2 order-1 align-middle text-center">
                              { otpCodeAccess?.otp }
                            </div>
                            <div className="align-middle order-2">
                              <CodeTimer nextAt={otpCodeAccess?.nextAt} period={2} />
                            </div>
                          </div>
                        : 
                          "Loading..." 
                        }
                </div>
              </div>
            </div>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200 dark:divide-primary-900">
                <div className="flex w-0 flex-1">
                  <button
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold bg-transparet hover:bg-gray-100"
                      onClick={handleCopy}
                  ><ClipboardIcon aria-hidden="true" className="h-5 w-5" /> {copyText}</button>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                  <button
                    className={clsx(["relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold", {"bg-indigo-300": codeReveal, "bg-transparent hover:bg-gray-100": !codeReveal}])}
                    onClick={handleReveal}
                  >
                    <EyeIcon aria-hidden="true" className="h-5 w-5" />
                    Reveal
                  </button>
              </div>
            </div>
        </div>
      </div>
    </div>
    )
}