import React, { useState, useEffect } from "react";
import {useOutletContext} from "react-router-dom";
import { Radio, RadioGroup } from '@headlessui/react'
import {loadStripe} from '@stripe/stripe-js';
import clsx from "clsx";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { PlusCircleIcon, MinusCircleIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";
import {
    EndpointOrganizationBillingCheckoutInit,
    EndpointOrganizationBillingSubscription,
    EndpointOrganizationBillingPortal,
    SubscriptionPlans,
    SubscriptionPeriod
} from "@/constants";
import {useHttpGet, useTranslation} from "@/hooks";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {OrgOutlet} from "@/types";
import {Button, SubscriptionTile} from "@/components/atoms";

type PageOrganizationBillingProps = {}

const stripePromise = loadStripe(window.ENV.STRIPE_PK, {});

declare global {
    namespace JSX {
      interface IntrinsicElements {
        'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
    }
  }

export const PageOrganizationBilling: React.FC<PageOrganizationBillingProps> = ({}) => {
    const {t} = useTranslation();
    const [ clientSecret, setClientSecret ] = useState("");
    const [ subQuantity, setSubQuantity ] = useState(1);
    const [ billingPeriod, setBillingPeriod ] = useState<SubscriptionPeriod>(SubscriptionPeriod.Annual);
    const { organization } =  useOutletContext<OrgOutlet>();
    const {addNotice} = useAppStateStore();

    const { execute: getSubscription, data: subscription, loading: subscriptionLoading } = useHttpGet(organization.key);
    const { execute: getBillingPortalSession } = useHttpGet(organization.key);
    const { execute: getCheckoutSession } = useHttpGet(organization.key);

    useEffect(() => {
        if (!organization.uuid) {
            return
        }

        // Create PaymentIntent as soon as the page loads
        getSubscription(EndpointOrganizationBillingSubscription(organization.uuid), {}).catch((e) => {
            console.log(e);
            addNotice({
                style: "error",
                type: "notify",
                easyDismiss: true,
                message: t("Failed to load subscription details"),
            })
        })
    }, [organization.uuid]);

    const createPortalSession = (uuid: string) => {
        getBillingPortalSession(EndpointOrganizationBillingPortal(uuid), {}).then((data)=>{
            window.open(data.url, "_blank");
        }).catch((e) => {
            console.log(e);
            addNotice({
                style: "error",
                type: "notify",
                easyDismiss: true,
                message: t("Failed to create billing portal session"),
            })
        })
    }

    const createCheckoutSession = (uuid: string, plan: string, quantity: number) => {
        getCheckoutSession(EndpointOrganizationBillingCheckoutInit(uuid, plan, quantity), {}).then((data) => {
            setClientSecret(data.client_secret)
        }).catch((e) => {
            console.log(e);
            addNotice({
                style: "error",
                type: "notify",
                easyDismiss: true,
                message: t("Failed to create checkout session"),
            })
        })
    }

    const subStatusCalc = () => {
        // Stripe doesn't have one place where it indicates a subscription will end, so we have to interpret that period of time from two places.
        // Also, formatting any pass-thru status to be more human friendly.

        if (subscription.status && !subscription.canceled) {
            return t("active")
        } else if (subscription.status && subscription.canceled) {
            return t("will deactivate")
        } else {
            return subscription.status.replaceAll("_", " ")
        }
    }

    // const totalCostCalculation = (
    //     baseCost: number[],
    //     unitCost: number[],
    //     units: number,
    //     seatsIncluded: number,
    //     costIndex: number
    // ) => {
    //     const costCalc = units > seatsIncluded && unitCost.length > 0 ? (baseCost[costIndex] + ((units - seatsIncluded) * unitCost[costIndex])) : baseCost[costIndex];
    //     return costCalc;
    // };

    const adjustSubQuantity = (increment?: number | null, replacement?: string) => {
        // Handle both incrementing quantity as well as replacing quantity
        if (increment) {
            if (subQuantity > 1 && increment < 0 || subQuantity < 100 && increment > 0) {
                // If incrementing, check to ensure quantity is within plan bounds, then allow adjustment
                setSubQuantity(subQuantity + increment);
            }
        } else if (replacement) {
            // If we're replacing the number, let's get the number from the input
            const rep = parseInt(replacement)
            if (rep < 0) {
                // If too low, set at bottom of boundry
                setSubQuantity(1)
            } else if (rep > 100) {
                // If too high, set at top of boundry
                setSubQuantity(100)
            } else {
                // Otherwise we're somewhere in between, so let's take in the argument
                setSubQuantity(rep)
            }
        }
    }

    // Show a loading indicator if the API hasn't returned the subscription details
    if (!subscription || subscriptionLoading) {
        return <div>
            <h1>LOADING</h1>
        </div>
    }

    const isCancelled = () => {
        return (subscription.status && subscription.canceled);
    }

    const isActive = () => {
        return (subscription.status && !subscription.canceled);
    }

    const renderSubscriptionSettings = () => {
        if (!organization.uuid || subscription.status === "none") {
            return null
        }
        return (
            <div>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-white-900 sm:text-4xl">{t("Billing for")} {organization.label}</h1>
                <div className="container mx-auto mt-8">
                    <div>
                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">{t("Status")}</dt>
                                <dd className={clsx("mt-1 text-3xl font-semibold tracking-tight capitalize", {
                                        ["text-gray-900"]: isActive(),
                                        ["text-red-500"]: !isActive()
                                    }
                                )}>
                                    {subStatusCalc()}
                                </dd>
                                {isCancelled() && (
                                    <p className="mt-2 max-w-xl text-sm text-gray-500">{t("Your subscription was cancelled on")} {new Date(subscription.biling_period_end * 1000).toDateString()} {t("and will be deactivated at the end of the current cycle.")}</p>
                                )}
                            </div>
                            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">{t("Current Cycle Ends")}</dt>
                                <dd className="mt-1 text-3xl font-semibold tracking-tight">{new Date(subscription.billing_period_end * 1000).toDateString()}</dd>
                            </div>
                            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">{t("Seats")}</dt>
                                <dd className="mt-1 text-3xl font-semibold tracking-tight">{subscription.seats}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="container mx-auto bg-white shadow sm:rounded-lg mt-4">
                    <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6">{t("Manage Subscription")}</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>{t("Opens a new tab to manage your settings. Change contact info, billing preferences, add/remove seats, view past invoices, or cancel your subscription.")}</p>
                        </div>
                        <div className="mt-5">
                            <Button onClick={() => createPortalSession(organization.uuid)}
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                {t("Manage plan")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderSubscriptionSelection = () => {
        if (!organization.uuid || subscription.status !== "none") {
            return null
        }
        return (
            <div className="container mx-auto sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <div>
                        {clientSecret ?
                            <div>
                                <button onClick={() => {
                                    setClientSecret("")
                                }} className="text-black"><ArrowLeftIcon className="w-4 h-4 inline"/>Back to Plan Selection
                                </button>
                                <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret: clientSecret}}>
                                    <EmbeddedCheckout/>
                                </EmbeddedCheckoutProvider>
                            </div>
                            :
                            <div className="text-primary-900 dark:text-primary-100">
                                <h3 className="text-base font-semibold leading-6">{t("Subscribe to a Plan")}</h3>
                                <p className="mt-2 max-w-xl text-sm my-5">{t("Use the inputs below to see what plans work for your needs and what it will cost.")}</p>
                                <div className="flex md:flex-row flex-col pb-8 gap-6">
                                    <div className="basis-1/2">
                                        <p className="font-bold">{t("1: Select your preferred Billing cycle:")}</p>
                                        <RadioGroup value={billingPeriod} onChange={(value) => setBillingPeriod(value as SubscriptionPeriod)} className="mt-2 grid grid-cols-2 gap-3">
                                            {Object.values(SubscriptionPeriod).map((plan) => (
                                                <Radio
                                                    key={plan}
                                                    value={plan}
                                                    disabled={false}
                                                    className={clsx(
                                                        (true ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25'),
                                                        'flex items-center justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold uppercase text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset',
                                                    )}
                                                >
                                                        <span className='capitalize'>
                                                            {plan}
                                                        </span>
                                                </Radio>
                                            ))}
                                        </RadioGroup>
                                    </div>
                                    <div className="basis-1/2">
                                        <p className="font-bold">{t("2: Select the number of people on your plan:")}</p>
                                        <div className="flex flex-row shadow-sm mt-2">
                                            <button
                                                className="relative inline-flex text-sm ring-inset w-4 items-center justify-center bg-slate-300 text-black basis-1/4 rounded-l-lg p-3"
                                                onClick={() => {
                                                    adjustSubQuantity(-1)
                                                }}><MinusCircleIcon className="h-4"/></button>
                                            <input
                                                type="number"
                                                value={subQuantity}
                                                className="block w-full rounded-none border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 basis-2/4"
                                                onChange={(e) => {
                                                    adjustSubQuantity(null, e.target.value)
                                                }}/>
                                            <button
                                                className="relative inline-flex text-sm ring-inset w-4  items-center justify-center bg-slate-300 text-black basis-1/4 rounded-r-lg p-3"
                                                onClick={() => {
                                                    adjustSubQuantity(1)
                                                }}><PlusCircleIcon className="h-4 inline"/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex md:flex-row flex-col gap-6">
                                    {SubscriptionPlans.map((plan) =>
                                        <div key={plan.id} className="basis-1/2">
                                            <SubscriptionTile
                                                plan={plan}
                                                checkoutFunc={createCheckoutSession}
                                                disabled={(plan.maxUnits && plan.maxUnits < subQuantity) || (plan.billingPeriods.indexOf(billingPeriod) < 0)}
                                                billingPeriod={billingPeriod}
                                                subQuantity={subQuantity}
                                                orgUuid={organization.uuid}
                                            />
                                        </div>
                                    )}
                                </div>
                                {/* {sessionSecret &&
                                        <stripe-pricing-table
                                            pricing-table-id="prctbl_1Plyd9Cy1SNRQqCAGd2hWcob"
                                            publishable-key="pk_test_51KaigECy1SNRQqCAJRpyjEUsi42oWtcxHJmAV9wKxNXg48xfUUREBhBt0hoxnHojCANX36vWDMzN8kWXYqbOGC8a00f7s9miLB"
                                            customer-session-client-secret={sessionSecret}
                                            >
                                        </stripe-pricing-table>} */}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="">
            {renderSubscriptionSettings()}
            {renderSubscriptionSelection()}
        </div>
    );
}
