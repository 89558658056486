import React from "react"
import {LockOpenIcon, LockClosedIcon} from "@heroicons/react/24/solid";
import {useTranslation, useWorker} from "@/hooks";
import {useCommStateStore} from "@/stores/commState.ts";

type CryptoStatusProps = {}

export const CryptoStatus: React.FC<CryptoStatusProps> = () => {
    const {t} = useTranslation();
    const [worker] = useWorker();
    const {unlockedOrgs} = useCommStateStore();

    const handleLock = () => {
        if (!worker) {
            return; // no need to report, if worker isn't working, then nothing is unlocked
        }

        unlockedOrgs.forEach(async (orgKey) => {
            await worker.LockOrg(orgKey);
        });
    }

    return (
        <button
            type="button"
            onClick={handleLock}
            className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mx-2"
        >
            <span className="absolute -inset-1.5"/>
            <span className="sr-only">{ unlockedOrgs.length > 0 ? t("Lock all organizations") : t("No organizations currently unlocked") }</span>
            { unlockedOrgs.length > 0 ? <LockOpenIcon aria-hidden="true" className="h-6 w-6"/> : <LockClosedIcon aria-hidden="true" className="h-6 w-6"/>}
        </button>
    )
}