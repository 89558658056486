import React, {useEffect} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {useHttpGet, useTranslation} from "@/hooks";
import {AppIds, EndpointOrganizationApp, UrlOrganizationGroup} from "@/constants";
import {PageOrganizationAppsConfigureConfigWeb} from "./configs/WebConfig";
import {PageOrganizationAppsConfigureConfigSlack} from "./configs/SlackConfig";
import {Link} from "@/components/atoms";
import {AppUserManager} from "@/components/organisms";
import {OrganizationAppGroup, OrganizationAppStatus, OrgOutlet} from "@/types";

interface PageOrganizationAppsConfigureProps {}

enum AppSettingSections {
    General = "General",
    Users = "Users",
    Groups = "Groups",
}

type AppSettings = {
    id: AppIds
    component: React.ComponentType<any>,
    sections: Array<AppSettingSections>
}

const appSettingsMap: { [key: string]: AppSettings } = {
    [AppIds.Slack]: {
        id: AppIds.Slack,
        component: PageOrganizationAppsConfigureConfigSlack,
        sections: [AppSettingSections.General, AppSettingSections.Users, AppSettingSections.Groups]
    },
    [AppIds.Web]: {
        id: AppIds.Web,
        component: PageOrganizationAppsConfigureConfigWeb,
        sections: [AppSettingSections.General]
    }
};

export const PageOrganizationAppsConfigure: React.FC<PageOrganizationAppsConfigureProps> = ({}) => {
    const {t} = useTranslation();
    const {orgAppUuid} = useParams();
    const {organization} = useOutletContext<OrgOutlet>();
    const {execute: getApp, data: app} = useHttpGet(organization.key);
    const appSettings = appSettingsMap[app?.app_id || ""] || {id: app?.app_id, component: React.Fragment, sections: []} as AppSettings;

    let showInstallButton = false;
    let showAuthButton = false;

    useEffect(() => {
        getApp(EndpointOrganizationApp(organization.uuid, orgAppUuid || ""), {});
    }, [orgAppUuid, organization.key]);

    if (app) {
        // if status is uninstalled, show the install button
        showInstallButton = app.status === OrganizationAppStatus.Uninstalled;

        // if app user is an admin, but has no user access token, show the install button, but we should change the label
        showAuthButton = (app.status === OrganizationAppStatus.NotAuthorized || (!app.current_user.has_sso_auth && app.current_user.app_admin));
    }

    const renderAppInfo = () => {
        if (!app) {
            return;
        }

        switch (app.app_id) {
            case AppIds.Slack:
                return <PageOrganizationAppsConfigureConfigSlack app={app} />
            default:
                return <PageOrganizationAppsConfigureConfigWeb />
        }
    }

    const renderUserList = () => {
        if (!app || showInstallButton || showAuthButton) {
            return null;
        }
        return (
            <div className="bg-white p-4 mt-8 shadow-sm ring-1 ring-gray-900/5 rounded-lg md:col-span-2">
                <AppUserManager organization={organization} organizationApp={app} />
            </div>
        );
    }

    const renderGroups = () => {
        if (!app || showInstallButton || showAuthButton) {
            return null;
        }
        return (
            <div className="bg-white p-4 mt-8 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg md:col-span-2">
                <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6">{t("Authorized Groups")}</h1>
                            <p className="mt-2 text-sm">
                                {t("Below are a list a group you belong to that this app has access to")}
                            </p>
                        </div>
                    </div>
                    <div className="mt-3 sm:ml-4 sm:mt-0">
                        <label htmlFor="mobile-search-groups" className="sr-only">
                            Search
                        </label>
                        <label htmlFor="desktop-search-groups" className="sr-only">
                            Search
                        </label>
                        <div className="flex rounded-md shadow-sm">
                            <div className="relative flex-grow focus-within:z-10">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5"/>
                                </div>
                                <input
                                    id="mobile-search-groups"
                                    name="mobile-search-groups"
                                    type="text"
                                    placeholder={t("Search")}
                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
                                />
                                <input
                                    id="desktop-search-groups"
                                    name="desktop-search-groups"
                                    type="text"
                                    placeholder={t("Search groups")}
                                    className="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ul role="list" className="divide-y divide-gray-100">
                        {app.app_groups?.map((app_group: OrganizationAppGroup) => {
                            const group = app_group.group;
                            return (
                                <li key={group.uuid} className="flex items-center justify-between gap-x-6 py-5">
                                    <div className="flex min-w-0 gap-x-4">
                                        {/*<img alt="" src={person.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-50"/>*/}
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6">{group.label}</p>
                                            <p className="mt-1 truncate text-xs leading-5">{group.description}</p>
                                        </div>
                                    </div>
                                    <Link to={UrlOrganizationGroup(organization?.key || "", group.uuid)} className="text-indigo-600 hover:text-indigo-900">
                                        {t("View ")}<span className="sr-only">, {group.label}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="md:flex md:items-center md:justify-between">
                <div className="min-w-0 flex-1">
                    <h2 className="text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
                        {t(`Configure ${app?.label || ""}`)}
                    </h2>
                </div>
            </div>
            <div>
                {appSettings?.sections?.includes(AppSettingSections.General) && renderAppInfo()}
                {appSettings?.sections?.includes(AppSettingSections.Users) && renderUserList()}
                {appSettings?.sections?.includes(AppSettingSections.Groups) && renderGroups()}
            </div>
        </div>
    );
};