import React from "react";
import {Button} from "@/components/atoms"
import { UrlLogin, UrlOrganizationCreate } from "@/constants";
import {useAppStateStore} from "@/stores/keyforgeState.ts"
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

type PageOrganizationSelectionProps = {}

export const PageOrganizationSelection: React.FC<PageOrganizationSelectionProps> = () => {
    const {getOrgs} = useAppStateStore();

    return (
        <div className="mx-auto max-w-prose bg-white text-black px-4 py-6 rounded-md border border-primary-100 shadow-sm">
            <div>
                <h2 className="font-md font-bold mb-2">Select an Organization</h2>
                <div className="flex flex-col gap-2">
                    {getOrgs().length > 0 ? getOrgs().map((org)=>
                        <Link key={org.organization.key} to={`/organizations/${org.organization.key}`} className="border border-primary-100 p-2 w-full block hover:bg-primary-50">
                            <div className="flex flex-row justify-between place-items-center">
                                <div>
                                    {org.organization.label}
                                </div>
                                <div>
                                    <ChevronRightIcon width={35} />
                                </div>
                            </div>
                        </Link>
                    ) : <div key="none">No Organizations Found</div>}
                </div>
                <div className="flex flex-row gap-2 mt-4">
                    <Button to={UrlOrganizationCreate()} className="basis-1/2" color="primary" outline={true}>Create New Organization</Button>
                    <Button to={UrlLogin()} className="basis-1/2" color="primary">Log Into Organization</Button>
                </div>
            </div>
        </div>
    );
}
