import React from "react";
import clsx from "clsx";

type ProgressStepsProps = {
    stepLabels: string[],
    activeIndex: number,
}

export const ProgressSteps: React.FC<ProgressStepsProps> = ({stepLabels = ["Default"], activeIndex = 0}) => {
    return (
        <ul className="grid grid-cols-3 gap-x-4 mb-8">
            {stepLabels.map((step, i)=>
                <li className={clsx("group flex flex-col align-top content-top text-center", {"active font-bold": i == activeIndex})} key={step}>
                    <div className="rounded-full border border-primary-500 w-8 h-8 mx-auto mb-2 content-center text-center group-[.active]:border-4 group-[.active]:bg-primary-25">{i + 1}</div>
                    <div>{step}</div>
                </li>
            )}
        </ul>
    )
}