import React, {useEffect, useState} from "react";
import {useOutletContext, useParams, useNavigate} from "react-router-dom";
import QrScanner from "qr-scanner";
import {CalendarDaysIcon, CodeBracketIcon, PencilIcon} from "@heroicons/react/24/outline";
import {useHttpGet, useHttpPost, useTranslation, useWorker} from "@/hooks";
import {EncIdLength, OtpIdLength, EndpointOrganizationCodeUpdate, EndpointOrganizationCodeGetOne, UrlHome, UrlOrganizationGroup} from "@/constants";
import {useCodeStore} from "@/stores/codeState.ts";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {OrgOutlet} from "@/types";
import {parseCodeUri} from "@/utils/codes.ts";
import {Input, Link} from "@/components/atoms";
import {Dropzone} from "@/components/molecules";

type PageOrganizationCodeManageProps = {}

export const PageOrganizationCodeManage: React.FC<PageOrganizationCodeManageProps> = () => {
    const {t} = useTranslation();
    const [worker] = useWorker();
    const navigate = useNavigate();
    const {code_uuid: codeUuid} = useParams();
    const {organization} = useOutletContext<OrgOutlet>();
    const [uri, setUri] = useState<string>("");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const {code: {label, alias, encrypted_value, issuer, account, created_at, updated_at}, setLabel, setAlias, setIssuer, setAccount, load} = useCodeStore();
    const {addNotice} = useAppStateStore();
    const {execute: getCode, data: code, loading} = useHttpGet(organization?.key);
    const {execute: updateCode} = useHttpPost(organization?.key);

    useEffect(() => {
        if (!organization || !codeUuid) {
            return
        }
        getCode(EndpointOrganizationCodeGetOne(organization.uuid, codeUuid), {}).then(load);
    }, [organization?.uuid, codeUuid])

    const hasUnpersistedChanges = () => {
        if (!code) {
            return false;
        }
        if (code.label !== label || code.alias !== alias || code.encrypted_value !== encrypted_value ||
            code.issuer !== issuer || code.account !== account) {
            return true;
        }
        return false;
    }

    const onSave = async (e: any) => {
        e.preventDefault();
        if (!organization) {
            return
        }

        const payload: {[key: string]: any} = {}
        if (code.label !== label) {
            payload["label"] = label;
        }
        if (code.alias !== alias) {
            payload["alias"] = alias;
        }
        if (code.issuer !== issuer) {
            payload["issuer"] = issuer;
        }
        if (code.account !== account) {
            payload["account"] = account;
        }
        if (uri !== "") {
            if (!worker) {
                return
            }

            try {
                const groupUserCreds = code.group.group_users[0].credentials
                const groupCreds = code.group.credentials
                const codeData = {
                    uri: uri
                }
                payload["enc_id"] = await worker.RandomString(EncIdLength);
                payload["enc_id"] = await worker.RandomString(OtpIdLength);
                payload["encrypted_payload"] = await worker.AddCode(organization.key, groupUserCreds, groupCreds, codeData)
            } catch (e: any) {

            }
            payload["encrypted_value"] = encrypted_value;
        }

        await updateCode(EndpointOrganizationCodeUpdate(organization.uuid, code.uuid), payload, {}).then(() => {
            addNotice({
                type:"notify",
                style:"success",
                message: e.message || "Cannot log into this account at this time."
            })
            navigate(UrlHome());
        }).catch((err) => {
            console.log(err);
            addNotice({
                type:"notify",
                style:"error",
                message: "Failed to update code"
            })
        })
    }

    const handleFileUpload = async (uploadedFiles: Array<File>) => {
        const uploadedFile = uploadedFiles[0];
        const result = await QrScanner.scanImage(uploadedFile, {
            returnDetailedScanResult: true
        })
        setUri(result.data);

        // set data from uri
        const parsed = parseCodeUri(result.data)
        setIssuer(parsed?.issuer || "");
        setAccount(parsed?.label || "");
    }

    const renderOtpSection = () => {
        if (uri !== "") {
            return (
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                        <label htmlFor="issuer" className="block text-sm font-medium leading-6">
                            {t("Issuer")}
                        </label>
                        <div className="mt-2">
                            <Input
                                id="issuer"
                                name="issuer"
                                type="text"
                                value={issuer}
                                autoComplete="off"
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor="account" className="block text-sm font-medium leading-6">
                            {t("Account")}
                        </label>
                        <div className="mt-2">
                            <Input
                                id="account"
                                name="account"
                                type="text"
                                value={account}
                                autoComplete="off"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="mt-6">
                <Dropzone onDrop={handleFileUpload} enablePaste={true}/>
            </div>
        )
    }

    const renderOverview = () => {
        if (isEditing) {
            return null;
        }
        return (
            <div className="container mx-auto bg-white shadow sm:rounded-lg mt-4">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-base font-semibold leading-6">{t("General")}</h3>
                    <div className="mt-2 text-sm text-gray-500">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            {/*<div className="sm:col-span-1">*/}
                            {/*    <dt className="text-sm font-medium text-gray-500">Label</dt>*/}
                            {/*    <dd className="mt-1 text-sm text-gray-900">Value</dd>*/}
                            {/*</div>*/}
                            {/*<div className="sm:col-span-1">*/}
                            {/*    <dt className="text-sm font-medium text-gray-500">Label</dt>*/}
                            {/*    <dd className="mt-1 text-sm text-gray-900">Value</dd>*/}
                            {/*</div>*/}
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">{t("Label")}</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {code.label}
                                </dd>
                            </div>
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">{t("Alias")}</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {code.alias}
                                </dd>
                            </div>
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">{t("Group")}</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <Link to={UrlOrganizationGroup(organization.uuid, code.group.uuid)}>{code.group.label}</Link>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }

    const renderEdit = () => {
        if (!isEditing) {
            return null;
        }
        return (
            <div className="space-y-12">
                <div>
                    <h2 className="text-base font-semibold leading-7 ">{t("Editing Code")}</h2>
                    <p className="mt-1 text-sm leading-6">
                        {t("Updates to the code will be immediately reflected and affect everyone with access to this code.")}
                    </p>
                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="col-span-full">
                            <label htmlFor="label" className="block text-sm font-medium leading-6">
                                {t("Label")}
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="label"
                                    name="label"
                                    type="text"
                                    value={label}
                                    onChange={(e) => setLabel(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="alias" className="block text-sm font-medium leading-6">
                                {t("Alias")}
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="alias"
                                    name="alias"
                                    type="text"
                                    value={alias}
                                    onChange={(e) => setAlias(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pb-12">
                    <h2 className="text-base font-semibold leading-7">{t("Update OTP Code")}</h2>
                    <p className="mt-1 text-sm leading-6">
                        {t("Replace the existing MFA code with a newly generated on from the service provider")}
                    </p>
                    {renderOtpSection()}
                </div>

                <div className="pb-12">
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" onClick={() => {
                            load(code);
                            setUri("");
                            setIsEditing(false);
                        }} className="text-sm font-semibold leading-6">
                            {t("Cancel")}
                        </button>
                        {hasUnpersistedChanges() && (
                            <button
                                type="submit"
                                onClick={onSave}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {t("Save")}
                            </button>)}
                    </div>
                </div>
            </div>
        )
    }

    if (!code || loading) {
        return <p>Loading...</p>
    }

    return (
        <>
            <header className="">
                <div className="mx-auto max-w-7xl xl:flex xl:items-center xl:justify-between">
                    <div className="min-w-0 flex-1">
                        <h1 className="mt-2 text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            {label}
                        </h1>
                        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <CalendarDaysIcon aria-hidden="true" className="mr-1.5 size-5 shrink-0 text-gray-400"/>
                                Created on {created_at.toLocaleDateString()} {created_at.toLocaleTimeString()}
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <CalendarDaysIcon aria-hidden="true" className="mr-1.5 size-5 shrink-0 text-gray-400"/>
                                Last Updated {updated_at.toLocaleDateString()} {updated_at.toLocaleTimeString()}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 flex xl:ml-4 xl:mt-0">
                        { !isEditing && (
                        <span className="hidden sm:block">
                            <button
                                type="button"
                                onClick={() => setIsEditing(true)}
                                className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <PencilIcon aria-hidden="true" className="-ml-0.5 size-5 text-gray-400"/>
                                Edit
                            </button>
                        </span>
                        )}
                    </div>
                </div>
            </header>

            {renderOverview()}
            {renderEdit()}

            <div className="container mx-auto bg-white shadow sm:rounded-lg mt-4">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-base font-semibold leading-6">{t("Usage Stats")}</h3>
                    <div className="mt-2 text-sm text-gray-500">
                        <div className="text-center w-6/12 mx-auto py-8">
                            <CodeBracketIcon aria-hidden="true" className="mx-auto size-12 text-gray-400"/>
                            <h3 className="mt-2 text-sm font-semibold text-gray-900">{t("Under development")}</h3>
                            <p className="mt-1 text-sm text-gray-500">
                                {t("Rest assured, codes are being logged every time they are accessed. Viewing of this information is currently under active development.")}
                                <br/><br/>
                                {t("Feel free to reach out and tell us what metrics are most important to you.")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}