import React, {useEffect, useState} from "react";
import {useHttpGet, useHttpPost, useTranslation, useWorker} from "@/hooks";
import {EndpointOrganizationCreate, EndpointOrganizationInit, StepCreateOrg} from "@/constants";
import {formatSecretKey} from "@/utils/common";
import {useNewOrgStore} from "@/stores/newOrgState.ts";
import CreateUser from "./CreateUser";
import CreateOrganization from "./CreateOrganization";
import {AccountDetails} from "@/components/molecules";
import { ProgressSteps } from "@/components/atoms";
import {AuthCreds, Group, GroupType, GroupUser} from "@/types";
import {useAppStateStore} from "@/stores/keyforgeState.ts";

type PageOrganizationCreateProps = {};

export const PageOrganizationCreate: React.FC<PageOrganizationCreateProps> = () => {
    const [worker] = useWorker();
    const {t} = useTranslation();
    const {newOrgState: {org_key, org_label, org_billing_email, user_fullname, user_nickname, user_email, user_password}, setUserEmail} = useNewOrgStore();
    const {addOrg} = useAppStateStore();
    const [tempSecretKey, setTempSecretKey] = useState<string>("");
    const [step, setStep] = useState(StepCreateOrg.StepOrgForm);
    const [stepInt, setStepInt] = useState(0); // Index of Step for Progress Bar

    const {execute: register, loading} = useHttpPost();
    const {execute: init} = useHttpGet();

    useEffect(() => {
        if (step === StepCreateOrg.StepUserForm) {
            // if step changes to the user form, and the user email hasn't been filled out, set it to the billing email
            if (user_email === "") {
                setUserEmail(org_billing_email);
            }
        }
    }, [step])
    const renderOverview = () => {
        return (<AccountDetails orgLabel={org_label} orgKey={org_key} email={user_email} secretKey={tempSecretKey} heading={t("Your new Organization has been created!")} />);
    }

    const createAccount = async () => {
        if (!worker) {
            // TODO: Add error handling
            return
        }
        const initData = await init(EndpointOrganizationInit(), {
            excludeAuthHeader: true,
        });
        const accountId = initData.account_id;
        const salt = initData.salt;

        const orgData = await worker.CreateOrg({
            orgKey: org_key,
            email: user_email,
            password: user_password,
            accountId: accountId,
            salt: salt,
            version: initData.version,
        });

        const hashedPassword = await worker.HashPassword(user_password);

        const newGroupCreds = await worker.CreateGroup(org_key)


        // setup default groups
        const personalGroup: Group = {
            label: "Personal",
            type: GroupType.Private,
            credentials: newGroupCreds.groupCreds,
            group_users: [{
                credentials: newGroupCreds.groupUserCreds,
            } as GroupUser]
        } as Group;

        const generalGroup: Group = {
            label: "General",
            description: "Default group for shared codes",
            type: GroupType.Shared,
            credentials: newGroupCreds.groupCreds,
            group_users: [{
                credentials: newGroupCreds.groupUserCreds,
            } as GroupUser]
        } as Group;

        const response = await register(EndpointOrganizationCreate(), {
            key: org_key,
            label: org_label,
            billing_email: org_billing_email,
            user: {
                account_id: accountId,
                fullname: user_fullname,
                nickname: user_nickname,
                email: user_email,
                password: hashedPassword,
                public_key: orgData.encodedPublicKey,
                private_key: orgData.encryptedPrivateKey,
                iv: orgData.privateKeyIv,
                salt: salt,
                groups: [personalGroup],
            },
            groups: [generalGroup],
        }, {
            excludeAuthHeader: true,
            credentials: 'include',
            isJSON: true,
        })

        addOrg(response.organization, response.user, {access_token: response.access_token, expiration: response.expiration} as AuthCreds)

        setTempSecretKey(formatSecretKey(orgData.secretKey));
        setStep(StepCreateOrg.StepOverview);
        setStepInt(2);
    }

    const renderStep = () => {
        switch (step) {
            case StepCreateOrg.StepOrgForm:
                return <CreateOrganization onSubmit={() => {
                    setStep(StepCreateOrg.StepUserForm);
                    setStepInt(1);
                }}></CreateOrganization>
            case StepCreateOrg.StepUserForm:
                return <CreateUser onSubmit={createAccount}></CreateUser>
            case StepCreateOrg.StepOverview:
                return renderOverview();
        }
    }

    const ProgressBarSteps = [
        "Create New Org",
        "Create Admin Account",
        "Review Details"
    ];

    return (
        <main className="flex flex-col items-center justify-between md:px-12">
            <ProgressSteps stepLabels={ProgressBarSteps} activeIndex={stepInt} />
            <hr className="border border-primary-100 w-full" />
            {loading && <div>{t("Creating organization")}</div>}
            {renderStep()}
        </main>
    );
}
