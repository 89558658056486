import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {PlusIcon, UsersIcon, Squares2X2Icon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {useHttpGet, useTranslation} from "@/hooks";
import {EndpointOrganizationGroups, UrlOrganizationGroupsCreate} from "@/constants"
import {Button, Link} from "@/components/atoms";
import {Group, OrgOutlet} from "@/types";

type PageOrganizationGroupsProps = {}

export const PageOrganizationGroups: React.FC<PageOrganizationGroupsProps> = () => {
    const {t} = useTranslation();
    const {organization} = useOutletContext<OrgOutlet>();
    const {execute: getGroups, data: groups, loading} = useHttpGet(organization?.key);
    const [filter, setFilter] = useState<string>("");
    const [filteredGroupList, setFilteredGroupList] = useState<Array<Group>>([]);

    useEffect(() => {
        loadOrganizationGroups();
    }, [])

    const loadOrganizationGroups = async () => {
        if (!organization) {
            return;
        }
        getGroups(EndpointOrganizationGroups(organization.uuid) + "?include_counts=true", {})
    }

    useEffect(() => {
        if (!groups) {
            return;
        }

        setFilteredGroupList(groups.items?.filter((group: Group) => {
            return group.label.toLowerCase().includes(filter.toLowerCase());
        }));
    }, [groups, filter])

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    }

    const handleResetFilter = (e: any) => {
        e.preventDefault();
        setFilter("");
    }

    const renderGroupList = () => {
        if (filteredGroupList.length === 0 && groups?.length > 0) {
            return (
                <li className="py-4 w-full text-center">
                    <p className="text-sm font-medium">{t("No groups found with filter")}</p>
                    <div className="p-4">
                        <Button className="cursor-pointer" onClick={handleResetFilter}>{t("Clear filter")}</Button>
                    </div>
                </li>
            );
        }
        return (filteredGroupList || []).map((group: Group) => {
            return (
                <li
                    key={group.uuid}
                    className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
                >
                    <Link to={`./${group.uuid}`} className="block">
                        <span className="block">
                            <p className="text-sm font-semibold leading-6">
                                {group.label}
                            </p>
                            <span className="block mt-1 flex items-center gap-x-2 text-xs leading-5">
                                <p>
                                    {group.description}
                                </p>
                            </span>
                        </span>
                    </Link>
                    <dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
                        <div className="flex w-16 gap-x-2.5">
                            <dt>
                                <span className="sr-only">{t("Total Users")}</span>
                                <UsersIcon aria-hidden="true" className="h-6 w-6" title={t("Total users")} />
                            </dt>
                            <dd className="text-sm leading-6">{group.user_count}</dd>
                        </div>
                        <div className="flex w-16 gap-x-2.5">
                            <dt>
                                <span className="sr-only">{t("Apps granted access")}</span>
                                <Squares2X2Icon aria-hidden="true" className="h-6 w-6" title={t("Apps granted access")} />
                            </dt>
                            <dd className="text-sm leading-6">{group.app_count}</dd>
                        </div>
                    </dl>
                </li>
            );
        });
    }

    const renderHeading = () => {
        return (
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-base leading-6 text-sm">{t("A list of all of the organization groups you are in")}</h3>
              <div className="mt-3 sm:ml-4 sm:mt-0">
                  <div className="inline-block mr-2 font-sm">
                      <Button to={`${UrlOrganizationGroupsCreate(organization?.key || "")}`} className="text-sm rounded text-white p-2">
                          <PlusIcon aria-hidden="true" className="h-5 w-5 text-white inline-block" />
                          <span>Create Group</span>
                      </Button>
                  </div>
                  <div className="inline-block">
                      <label htmlFor="mobile-search-groups" className="sr-only">
                          Search
                      </label>
                      <label htmlFor="desktop-search-groups" className="sr-only">
                          Search
                      </label>
                      <div className="flex rounded-lg shadow-sm">
                          <div className="relative flex-grow focus-within:z-10">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400"/>
                              </div>
                              <input
                                  id="mobile-search-groups"
                                  name="mobile-search-groups"
                                  type="text"
                                  onChange={handleFilterChange}
                                  value={filter}
                                  placeholder="Search"
                                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
                              />
                              <input
                                  id="desktop-search-groups"
                                  name="desktop-search-groups"
                                  type="text"
                                  onChange={handleFilterChange}
                                  value={filter}
                                  placeholder="Search groups"
                                  className="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
                              />
                              {/*className="block w-full text-black rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        )
    }

    if (loading) {
        return <p>Loading...</p>
    }
    return (
        <div>
            {renderHeading()}
            <ul role="list" className="mt-2">
                {renderGroupList()}
            </ul>
        </div>
    );
}
