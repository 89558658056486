import { create } from "zustand";
import { AuthRequest} from "@/types";

// This stores contains state for communication between the worker and app
export type CommState = {
    authRequest: AuthRequest | null;
    unlockedOrgs: Array<string>;
    setAuthRequest: (request: AuthRequest | null) => void;
    setUnlockedOrgs: (orgs: Array<string>) => void;
};

export const useCommStateStore = create<CommState>((set: any) => ({
    authRequest: null,
    unlockedOrgs: [],
    setAuthRequest: (request: AuthRequest | null) => {
        set(() => ({ authRequest: request }));
    },
    setUnlockedOrgs: (orgs: Array<string>) => {
        set(() => ({ unlockedOrgs: orgs }));
    }
}));