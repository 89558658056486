import React from "react";
import {Page, Text, Image, View, Document, StyleSheet} from "@react-pdf/renderer";
import lightLogo from "@/assets/logo/key_forge_light.png"

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor: "#D5E0FF",
    },
    section: {
        margin: 10,
        padding: 35,
        flexGrow: 1
    },
    header: {
        fontSize: 26,
        textAlign: "center",
        marginBottom: 20,
        textTransform: "uppercase"
    },
    p: {
        marginBottom: 2,
        fontSize: 16,
    },
    b: {
        fontWeight: "bold",
    },
    headerDetails: {
        fontSize: 24,
    },
    details: {
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
    logo: {
        width: 150,
        textAlign: "center"
    }
});

type RecoveryDocumentProps = {
    orgKey: string;
    secretKey: string;
}

const today = new Date();

export const RecoveryDocument: React.FC<RecoveryDocumentProps> = ({ orgKey, secretKey}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image src={lightLogo} style={styles.logo} />
                    <Text style={styles.header}>Key Forge Account Recovery Kit</Text>
                    <Text style={styles.p}>This PDF contains important info required to access your account. Ensure to safeguard this document, only those who have this information will be able to access your encrytped data.</Text>
                    <Text style={styles.p}>Key Forge will never have access to your data, so if this information is lost then your data is unrecoverable.</Text>
                </View>
                <View style={{...styles.section, ...styles.details}}>
                    <Text style={{...styles.header, ...styles.headerDetails}}>Recovery Details</Text>
                    <View style={styles.section}>
                        <Text style={{...styles.p, ...styles.b}}>Organization Key:</Text>
                        <Text style={styles.p}>{orgKey}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={{...styles.p, ...styles.b}}>Secret Key:</Text>
                        <Text style={{...styles.p}}>{secretKey}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={{...styles.p, ...styles.b}}>Account Creation Date:</Text>
                        <Text style={{...styles.p}}>{`${today}`}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};