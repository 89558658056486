import React, { createContext, useEffect, useMemo, useState } from "react";
import { Outlet, useParams, useLocation, useNavigate } from "react-router-dom";
import { useHttpGet, useTranslation } from "@/hooks";
import { Link, LoadingIcon } from "@/components/atoms";
import {useAppStateStore} from "@/stores/keyforgeState.ts"
import {Organization, OrganizationStatus, OrgOutlet} from "@/types"
import {EndpointOrganizationGet, UrlHome, UrlOrganizationBilling} from "@/constants"
import {
    UsersIcon,
    CreditCardIcon,
    RectangleGroupIcon,
    UserGroupIcon,
    BuildingOffice2Icon,
    ChevronLeftIcon
} from "@heroicons/react/24/outline"
import clsx from "clsx";

const OrgContext = createContext<{ organization: Organization | null}>({organization: null});

type LayoutOrganizationProps = {};

export const LayoutOrganization: React.FC<LayoutOrganizationProps> = () => {
    const {getOrgByKey, setLastOrgKey} = useAppStateStore();
    let { t } = useTranslation();
    let [forceBilling, setForceBilling] = useState<boolean>(false);
    let [currentOrg, setCurrentOrg] = useState<Organization | null>(null);
    let { orgKey } = useParams();
    let location = useLocation();
    const { execute: getOrganization } = useHttpGet(orgKey);
    const redirect = useNavigate()

    const secondaryNavigation = useMemo(() => [
        { key: t("Settings"), href: `/organizations/${orgKey}/settings`, icon: <BuildingOffice2Icon /> },
        { key: t("Billing"), href: `/organizations/${orgKey}/billing`, icon: <CreditCardIcon /> },
        { key: t("Users"), href: `/organizations/${orgKey}/users`, icon: <UsersIcon /> },
        { key: t("Apps"), href: `/organizations/${orgKey}/apps`, icon: <RectangleGroupIcon /> },
        { key: t("Groups"), href: `/organizations/${orgKey}/groups`, icon: <UserGroupIcon /> },
    ], [orgKey]);

    useEffect(()=>{ 
        if (orgKey) {
            const orgState = getOrgByKey(orgKey)
            if (orgState) {
                const orgUuid = orgState.organization.uuid
                getOrganization(EndpointOrganizationGet(orgUuid), {}).then((result) => {
                    setCurrentOrg(result);
                    setLastOrgKey(orgState?.organization?.key || "");
                });
                return
            }
        } else {
            // organization route hit without an org key, redirect to home
            redirect(UrlHome(), {replace: true})
        }
    }, [orgKey])

    useEffect(() => {
        if (!currentOrg) {
            return
        }
        if (currentOrg.status === OrganizationStatus.Inactive ) {
            setForceBilling(true);
            if (location.pathname !== UrlOrganizationBilling(orgKey!)) {
                redirect(UrlOrganizationBilling(orgKey!), {replace: true})
            }
        } else {
            if (forceBilling) {
                setForceBilling(false)
            }
        }
    }, [currentOrg, location])

    return (
        <OrgContext.Provider value={{ organization: currentOrg }}>
            <div className="text-primary-25 relative m-height-full mx-auto max-w-5xl">
                {/* Settings Navigation */}
                <header className="border-b border-white/5 flex sm:flex-row flex-col justify-between gap-5 pt-5">
                    {/* Organization Selector */}
                    <Link to="/organizations" className="relative inline-block text-left text-primary-900">
                        <ChevronLeftIcon width={35} className="inline" />&nbsp;{currentOrg?.label}
                    </Link>
                    <nav className="flex col-span-2 justify-center text-primary-900 dark:text-primary-100">
                        <ul
                            role="list"
                            className="-mb-px flex space-x-8"
                        >
                            {secondaryNavigation.map((item) => (
                                <li key={t(item.key)} className="group flex justify-evenly">
                                    <Link to={item.href} className={clsx(
                                        'flex flex-col border-b-4 items-center content-center',
                                        {
                                            'border-primary-300': location.pathname === item.href,
                                            'hover:text-indigo-300': location.pathname !== item.href
                                        }, 
                                    )}>
                                        <div className="size-8">
                                            {item.icon}
                                        </div>
                                        <div>
                                            {t(item.key)}
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </header>
                <div className="min-h-10 p-4 border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500">
                    {currentOrg && <Outlet context={{organization: currentOrg} as OrgOutlet} />}
                    {!currentOrg && (
                        <div className="text-center m-8">
                            <h2>{t("Loading organization details...")}</h2>
                            <LoadingIcon />
                        </div>
                    )}
                </div>
            </div>
        </OrgContext.Provider>
    );
}