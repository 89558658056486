import { Organization } from "./organization"
import { Group } from "./group"

export type Code = {
    uuid: string;
    label: string
    alias: string
    organization_uuid: string;
    group_uuid: string;
    issuer: string;
    account: string;
    encrypted_value: ParsedEncryptedCode;
    created_at: Date;
    updated_at: Date;

    group: Group
    organization: Organization
    // not in API response, just in the render
    active: boolean
    matchScore: number
    match: boolean
}

export type CodeCreds = {
    uri: string;
}

export type CodeAccess = {
    otp: string
    nextAt: number
    period: number
    // (current - nextAt) = time remaining
    // time remaining + (period * 2) = total time to display reveal
}

export type ParsedEncryptedCode = {
    groupKey: string,
    codeIv: string,
    encryptedCode: string,
}

export interface ParsedUri {
    label: string;
    issuer: string;
}

export const getDefaultCode = (): Code => {
    return {
        uuid: "",
        label: "",
        alias: "",
        organization_uuid: "",
        group_uuid: "",
        issuer: "",
        account: "",
        encrypted_value: {} as ParsedEncryptedCode,
        created_at: new Date(),
        updated_at: new Date(),

        group: {} as Group,
        organization: {} as Organization,
        // not in API response, just in the render
        active: false,
        matchScore: 0,
        match: false,
    }
}